import React, { useState } from "react";
import "./Tabs.css"
export const Tabs = ({student,setForm,form,studentNotice}) => {
    
    const handleStudentTabChange = (student) => {
		setForm({...form,student:student});
	};
    return (
        <div className="tabs">
        {typeof student != "string" &&
            student != undefined &&
            student.map((student) => (
                <p
                    className="tab"
                    style={{
                        backgroundColor:
                            form?.student == student.email && "#FFF4DE",
                    }}
                    onClick={() => handleStudentTabChange(student.email)}
                >
                    {student.first_name + " " + student.last_name}{studentNotice.includes(student.email)&&<span className="exclaim">!</span>}
                </p>
            ))}
    </div>
    );
  };