import React, { useState } from "react";
import "./Tabs.css"
export const Tabs = ({teacher,setForm,form}) => {
    
    const handleTeacherTabChange = (teacher) => {
		setForm({...form,teacher:teacher});
	};
    return (
        <div className="tabs">
        {typeof teacher != "string" &&
            teacher != undefined &&
            teacher.map((teacher) => (
                <p
                    className="tab"
                    style={{
                        backgroundColor:
                            form?.teacher == teacher.email && "#FFF4DE",
                    }}
                    onClick={() => handleTeacherTabChange(teacher.email)}
                >
                    {teacher.first_name + " " + teacher.last_name}
                </p>
            ))}
    </div>
    );
  };