import axios from 'axios';




export const logIn = (formData) => axios.post('https://nxg8ojimla.execute-api.ap-southeast-2.amazonaws.com/default/logIn', formData);
export const auth = (formData) => axios.post('https://5u28azg981.execute-api.ap-southeast-2.amazonaws.com/default/authorizeUser', formData);
export const validate = (formData) => axios.post('https://s35kqy3f8i.execute-api.ap-southeast-2.amazonaws.com/default/verify_validateUser', formData);
export const signUp = (formData) => axios.post('https://8a3d20qdu5.execute-api.ap-southeast-2.amazonaws.com/default/verify_registerUser', formData);
export const roles = (formData) => axios.post('https://06irhu0l58.execute-api.ap-southeast-2.amazonaws.com/default/rolesdisplay', formData);
export const schools = (formData) => axios.post('https://r06hwixik2.execute-api.ap-southeast-2.amazonaws.com/default/verify_schoolDisplay', formData);



export const userscount = (formData) => axios.post('https://mgc9w96pec.execute-api.ap-southeast-2.amazonaws.com/default/sysadmin_userCounts', formData);
export const schoolscount = (formData) => axios.post('https://ijdidv9sk2.execute-api.ap-southeast-2.amazonaws.com/default/sysadmin_schoolCount', formData);
export const schoolsdetails = (formData) => axios.post('https://cfgaka32f6.execute-api.ap-southeast-2.amazonaws.com/default/sysadmin_schoolDetails', formData);



export const curriculum = (formData) => axios.post('https://rko4eyyo3g.execute-api.ap-southeast-2.amazonaws.com/default/curriculumDisplay', formData);
export const schoolonboarding = (formData) => axios.post('https://2zojnmvpoe.execute-api.ap-southeast-2.amazonaws.com/default/registerSchool', formData);


export const newstudentcount = (formData) => axios.post('https://bni7z4xhti.execute-api.ap-southeast-2.amazonaws.com/default/newStudentIEP', formData);
export const studentassessmentcount = (formData) => axios.post('https://4ivzuonbbe.execute-api.ap-southeast-2.amazonaws.com/default/studentAssessment', formData);
export const populateyearlevels = (formData) => axios.post('https://6igba8w1re.execute-api.ap-southeast-2.amazonaws.com/default/populateYearLevels', formData);
export const yearleveldata = (formData) => axios.post('https://vpdfcsqk82.execute-api.ap-southeast-2.amazonaws.com/default/yearLevelData', formData);
export const newstudentdetails = (formData) => axios.post('https://sjr7n9q7ce.execute-api.ap-southeast-2.amazonaws.com/default/newStudentDetails', formData);
export const viewUpcomingOverdueReview = (formData) => axios.post('https://7r64ltneh5.execute-api.ap-southeast-2.amazonaws.com/default/viewUpcomingOverdueReview', formData);
export const viewAllReview = (formData) => axios.post('https://813a6egju2.execute-api.ap-southeast-2.amazonaws.com/default/viewAllReview', formData);
export const barGraphCounts = (formData) => axios.post('https://cfat6zuovg.execute-api.ap-southeast-2.amazonaws.com/default/barGraphCounts', formData);
export const viewAllReviewCount = (formData) => axios.post('https://3czjlp1wxe.execute-api.ap-southeast-2.amazonaws.com/default/viewAllReview_count', formData);
export const viewUpcomingOverdueReviewCount = (formData) => axios.post('https://3adngc0ro6.execute-api.ap-southeast-2.amazonaws.com/default/viewUpcomingOverdueReviewCount', formData);





export const studentdetails = (formData) => axios.post('https://fzjzco5sxc.execute-api.ap-southeast-2.amazonaws.com/default/studentDetails', formData);


export const userCount = (formData) => axios.post('https://sk942mzc92.execute-api.ap-southeast-2.amazonaws.com/default/userCount', formData);
export const activeStudent = (formData) => axios.post('https://flokmbhn3g.execute-api.ap-southeast-2.amazonaws.com/default/activeStudent', formData);
export const totalStudentDetails = (formData) => axios.post('https://36mimlri0k.execute-api.ap-southeast-2.amazonaws.com/default/totalStudentDetails', formData);
export const activeStudentDetails = (formData) => axios.post('https://xeu6wsruxf.execute-api.ap-southeast-2.amazonaws.com/default/activeStudentDetails', formData);



export const assignteachercoordinator = (formData) => axios.post('https://0njx6egiuj.execute-api.ap-southeast-2.amazonaws.com/default/stca_student_teacher_assign', formData);
export const roledisplay = (formData) => axios.post('https://5xv8x5z945.execute-api.ap-southeast-2.amazonaws.com/default/stca_roleDisplay', formData);



export const teacherNames = (formData) => axios.post('https://1b6uyaxhag.execute-api.ap-southeast-2.amazonaws.com/default/teacherNames', formData);
export const studentvoiceupdate = (formData) => axios.post('https://4d6mrmtumj.execute-api.ap-southeast-2.amazonaws.com/default/studentVoiceUpdate', formData);
export const reviewIEP = (formData) => axios.post('https://yi1wup5qth.execute-api.ap-southeast-2.amazonaws.com/default/reviewIEP', formData);
export const getSubcategoryOfSoftskill = (formData) => axios.post('https://sdxvqs8b9b.execute-api.ap-southeast-2.amazonaws.com/default/getSubcategoryOfSoftskill', formData);
export const getRootCauseOfSoftskill = (formData) => axios.post('https://l6xik07xyk.execute-api.ap-southeast-2.amazonaws.com/default/getRootCauseOfSoftskill', formData);
export const teacherTagging = (formData) => axios.post('https://3anyhs2ii9.execute-api.ap-southeast-2.amazonaws.com/default/teacherTagging', formData);
export const informationRecords = (formData) => axios.post('https://cn32ne5nac.execute-api.ap-southeast-2.amazonaws.com/default/viewInformIEP', formData);
export const create_student_view_review = (formData) => axios.post('https://36pdyi12ae.execute-api.ap-southeast-2.amazonaws.com/default/createStudentViewReview', formData);

export const forgotpassword = (formData)  => axios.post('https://c1i1qldjy4.execute-api.ap-southeast-2.amazonaws.com/default/forgetPassword', formData);
export const resetpassword = (formData)  => axios.post('https://jmi1x1n9u8.execute-api.ap-southeast-2.amazonaws.com/default/ResetPassword', formData);

export const iep_step_1_studentDetails = (formData) => axios.post('https://3cp8zu5u6k.execute-api.ap-southeast-2.amazonaws.com/default/iep_step_1_studentDetails', formData);
export const iep_step_1_impInfo = (formData) => axios.post('https://36yxz1u3f0.execute-api.ap-southeast-2.amazonaws.com/default/studentIEP_impInfo', formData);
export const iep_step_1_personalInfo = (formData) => axios.post('https://he7r7bqhtc.execute-api.ap-southeast-2.amazonaws.com/default/iep_step_1_personalInfo', formData);
export const iepid = (formData) => axios.post('https://zls8990bx2.execute-api.ap-southeast-2.amazonaws.com/default/iep_step1_iepID', formData);


export const supportstaff = (formData) => axios.post('https://iufmj5f96c.execute-api.ap-southeast-2.amazonaws.com/default/roleDisplaySupportStaff', formData);
export const assessment_type = (formData) => axios.post('https://j7yr258dki.execute-api.ap-southeast-2.amazonaws.com/default/iepStep2', formData);
export const iep_step2 = (formData) => axios.post('https://pi5mjakaw5.execute-api.ap-southeast-2.amazonaws.com/default/preInformation', formData);
export const viewIEPStep2 = (formData) => axios.post('https://wficij430m.execute-api.ap-southeast-2.amazonaws.com/default/viewIEPStep2', formData);
export const updateIEPStep2 = (formData) => axios.post('https://psl7gojxh9.execute-api.ap-southeast-2.amazonaws.com/default/updateIEPStep2', formData);




export const mapstudentviews = (formData) => axios.post('https://cw0qgcsik9.execute-api.ap-southeast-2.amazonaws.com/default/mapStudentViews', formData);
export const teachercomments = (formData) => axios.post('https://39p6x8wnyf.execute-api.ap-southeast-2.amazonaws.com/default/teacherComments', formData);


export const create_key_outcome = (formData) => axios.post('https://zwmb5fyjrc.execute-api.ap-southeast-2.amazonaws.com/default/createKeyOutcome', formData);
export const updateKeyOutcome = (formData) => axios.post('https://hb7tpnl4f3.execute-api.ap-southeast-2.amazonaws.com/default/updateKeyOutcome', formData);
export const viewKeyOutcome = (formData) => axios.post('https://p5n6ku2adj.execute-api.ap-southeast-2.amazonaws.com/default/viewKeyOutcome', formData);

export const view_stratergies_adjustments = (formData) => axios.post('https://3wsnn4i3ne.execute-api.ap-southeast-2.amazonaws.com/default/viewStrategiesAdjustments', formData);
export const create_stratergies_adjustments = (formData) => axios.post('https://qhn79oc5r2.execute-api.ap-southeast-2.amazonaws.com/default/StratergiesAdjustments_Create', formData);
export const edit_stratergies_adjustments = (formData) => axios.post('https://4qjf6dhn34.execute-api.ap-southeast-2.amazonaws.com/default/updateStratergiesAdjustments', formData);
export const delete_stratergies_adjustments = (formData) => axios.post('https://ajei2rke8l.execute-api.ap-southeast-2.amazonaws.com/default/deleteStratergiesAdjustments', formData);


export const view_subject = (formData) => axios.post('https://604ahrogwf.execute-api.ap-southeast-2.amazonaws.com/default/sra_viewSubject', formData);



export const iep_step3 = (formData) => axios.post('https://ew9g2xs83i.execute-api.ap-southeast-2.amazonaws.com/default/CreateIEPstep3', formData);
export const negotiate_goal = (formData) => axios.post('https://7srf1the14.execute-api.ap-southeast-2.amazonaws.com/default/negotiateGoal', formData);
export const viewIEPStep3 = (formData) => axios.post('https://y93yqbsdsa.execute-api.ap-southeast-2.amazonaws.com/default/viewIEPStep3', formData);
export const updateIEPStep3 = (formData) => axios.post('https://uzz8734uw6.execute-api.ap-southeast-2.amazonaws.com/default/updateIEPStep3', formData);
export const step3teacherTagging = (formData) => axios.post('https://3anyhs2ii9.execute-api.ap-southeast-2.amazonaws.com/default/teacherTagging', formData);


export const view_soft_skill = (formData) => axios.post('https://8yxqnzw87d.execute-api.ap-southeast-2.amazonaws.com/default/viewSoftSkill', formData);
export const create_soft_skill = (formData) => axios.post('https://fthjcrhjhh.execute-api.ap-southeast-2.amazonaws.com/default/createSoftSkill', formData);





export const view_review = (formData) => axios.post('https://1mhio78pwe.execute-api.ap-southeast-2.amazonaws.com/default/viewReview', formData);
export const create_review = (formData) => axios.post('https://m8y8hqmqfb.execute-api.ap-southeast-2.amazonaws.com/default/createReview', formData);
export const edit_review = (formData) => axios.post('https://zxy2gogwy7.execute-api.ap-southeast-2.amazonaws.com/default/editReview', formData);
export const delete_review = (formData) => axios.post('https://zxux16xsxj.execute-api.ap-southeast-2.amazonaws.com/default/deleteReview', formData);



export const view_category_of_concern = (formData) => axios.post('https://dbaj1bb9k9.execute-api.ap-southeast-2.amazonaws.com/default/viewLayer1', formData);
export const create_category_of_concern = (formData) => axios.post('https://zlobtna4w5.execute-api.ap-southeast-2.amazonaws.com/default/createCategoryOfConcern', formData);
export const edit_category_of_concern = (formData) => axios.post('https://wlis2r6tja.execute-api.ap-southeast-2.amazonaws.com/default/editCategoryOfConcern', formData);
export const delete_category_of_concern = (formData) => axios.post('https://ilx0bsltte.execute-api.ap-southeast-2.amazonaws.com/default/deleteCategoryOfConcern', formData);




export const get_new_student_duration = (formData) => axios.post('https://o152xq5mgl.execute-api.ap-southeast-2.amazonaws.com/default/getNewStudentDuration', formData);
export const update_new_student_duration = (formData) => axios.post('https://hws3fhnbm6.execute-api.ap-southeast-2.amazonaws.com/default/updateNewStudentDuration', formData);



export const teacherassessmentrequest = (formData) => axios.post('https://zwhun4jsc9.execute-api.ap-southeast-2.amazonaws.com/default/teacherAssessmentRequest', formData);


export const create_lesson_unit = (formData) => axios.post('https://av6mmoy228.execute-api.ap-southeast-2.amazonaws.com/default/createLessonUnit', formData);
export const view_lesson_unit = (formData) => axios.post('https://h7v49y8xzc.execute-api.ap-southeast-2.amazonaws.com/default/viewLessonUnit', formData);
export const update_lesson_unit = (formData) => axios.post('https://zxzqf23125.execute-api.ap-southeast-2.amazonaws.com/default/updateLessonUnit', formData);


export const user_profile_data = (formData) => axios.post('https://tjncnuythi.execute-api.ap-southeast-2.amazonaws.com/default/userProfile', formData);


export const student_profile = (formData) => axios.post('https://ij784egbnl.execute-api.ap-southeast-2.amazonaws.com/default/studentProfile', formData);


export const fileUpload = (formData) =>  axios.post('https://app.tollow.com.au/upload_iep', formData);


export const send_email= (formData) =>  axios.post('https://app.tollow.com.au/send_email_cheril', formData);



export const createModificationStudent = (formData) => axios.post('https://kr8ckbj9bd.execute-api.ap-southeast-2.amazonaws.com/default/createModificationStudent', formData);
export const updateModificationStudent = (formData) => axios.post('https://yk7nazcdqk.execute-api.ap-southeast-2.amazonaws.com/default/updateModificationStudent', formData);
export const viewModificationStudent = (formData) => axios.post('https://9g4sz3vew1.execute-api.ap-southeast-2.amazonaws.com/default/viewModificationStudent', formData);
export const modificationForStudentData = (formData) => axios.post('https://025w38xxob.execute-api.ap-southeast-2.amazonaws.com/default/modificationForStudentData', formData);
export const deleteModificationStudent = (formData) => axios.post('https://ojfexdq2dj.execute-api.ap-southeast-2.amazonaws.com/default/deleteModificationStudent', formData);
export const updateModificationStudentStatus = (formData) => axios.post('https://p2w66sb8p7.execute-api.ap-southeast-2.amazonaws.com/default/updateModificationStudentStatus', formData);






// export const review_past_iep = (formData) => axios.post('https://uj15m7ycg4.execute-api.ap-south-1.amazonaws.com/default/studentViewReviewStatus', formData);


export const viewPastIepFile = (formData) => axios.post('https://svq145zu3h.execute-api.ap-southeast-2.amazonaws.com/default/viewPastIepFile', formData);


export const viewPastFormalAssessmentFiles = (formData) => axios.post('https://sqvnf9gsfg.execute-api.ap-southeast-2.amazonaws.com/default/viewPastFormalAssessmentFiles', formData);

export const organise_formal_assessment= (formData) => axios.post('https://n0fs9tlm58.execute-api.ap-southeast-2.amazonaws.com/default/createFormalAssessment', formData);



export const studentconvodata= (formData) => axios.post('https://ihnu00w1ie.execute-api.ap-southeast-2.amazonaws.com/default/teacherAssignStudentDetails', formData);




export const insertShareSchool= (formData) => axios.post('https://0w0zolm8y0.execute-api.ap-southeast-2.amazonaws.com/default/insertShareSchool', formData);
export const deleteShareSchool= (formData) => axios.post('https://ri16ucrs44.execute-api.ap-southeast-2.amazonaws.com/default/deleteShareSchool', formData);
export const viewShareSchool= (formData) => axios.post('https://shgu4ltyg2.execute-api.ap-southeast-2.amazonaws.com/default/viewShareSchool', formData);







export const CreateRecordEvidence= (formData) => axios.post('https://vig9vpfauj.execute-api.ap-southeast-2.amazonaws.com/default/CreateRecordEvidence', formData);
export const FindStrategiesAdjustments= (formData) => axios.post('https://p4zgjttfy4.execute-api.ap-southeast-2.amazonaws.com/default/FindStrategiesAdjustments', formData);
export const CreateTargetedOutcome= (formData) => axios.post('https://ilb7w1e8hd.execute-api.ap-southeast-2.amazonaws.com/default/CreateTargetedOutcome', formData);
export const CreateTeacherStrategyRating= (formData) => axios.post('https://zhwmu8hrx0.execute-api.ap-southeast-2.amazonaws.com/default/CreateTeacherStrategyRating', formData);
export const LessonModification= (formData) => axios.post('https://10x0ah1lrd.execute-api.ap-southeast-2.amazonaws.com/default/LessonModifications', formData);
export const ui_logs= (formData) => axios.post('https://tc64t2ol77.execute-api.ap-southeast-2.amazonaws.com/default/UILogs', formData);

export const ViewKeyOutcome= (formData) => axios.post('https://c25i8gglu0.execute-api.ap-southeast-2.amazonaws.com/default/teacherViewKeyOutcome', formData);




export const CreateLessonPlan= (formData) => axios.post('https://k8hmkqoqna.execute-api.ap-southeast-2.amazonaws.com/default/CreateLessonPlan', formData);
export const updateLessonPlan= (formData) => axios.post('https://quvwt8b5bl.execute-api.ap-southeast-2.amazonaws.com/default/updateLessonPlan', formData);
export const viewLessonPlan= (formData) => axios.post('https://4vy5y09ff2.execute-api.ap-southeast-2.amazonaws.com/default/viewLessonPlan', formData);
export const ListingOfLessonPlan= (formData) => axios.post('https://e6gncfha00.execute-api.ap-southeast-2.amazonaws.com/default/ListingOfLessonPlan', formData);
export const targetedOutcome= (formData) => axios.post('https://o0vhdj2ja6.execute-api.ap-southeast-2.amazonaws.com/default/assessmentRelatedToTargetedOutcome', formData);
export const ListRecordEvidence= (formData) => axios.post('https://qew301nbs1.execute-api.ap-southeast-2.amazonaws.com/default/ListRecordEvidence', formData);
export const createUnitPlan= (formData) => axios.post('https://f7vqbbrlk7.execute-api.ap-southeast-2.amazonaws.com/default/createUnitPlan', formData);
export const updateUnitPlan= (formData) => axios.post('https://z38ei8tnhd.execute-api.ap-southeast-2.amazonaws.com/default/updateUnitPlan', formData);
export const viewUnitPlan= (formData) => axios.post('https://gd84lmv12e.execute-api.ap-southeast-2.amazonaws.com/default/viewUnitPlan', formData);
export const ViewAssessmentRelatedToTargetedOutcome= (formData) => axios.post('https://6yi3f8h888.execute-api.ap-southeast-2.amazonaws.com/default/ViewAssessmentRelatedToTargetedOutcome', formData);
export const ViewLessonModifications= (formData) => axios.post('https://4d795ltcd6.execute-api.ap-southeast-2.amazonaws.com/default/ViewLessonModifications', formData);
export const viewRecordEvidence= (formData) => axios.post('https://is7bt7ky86.execute-api.ap-southeast-2.amazonaws.com/default/viewRecordEvidence', formData);

export const CreateGeneralChat= (formData) => axios.post('https://qdj13w33c3.execute-api.ap-southeast-2.amazonaws.com/default/CreateGeneralChat', formData);
export const ViewGeneralChatByTeacher= (formData) => axios.post('https://o7ds654k5a.execute-api.ap-southeast-2.amazonaws.com/default/ViewGeneralChatByTeacher', formData);
export const ViewGeneralChatByCoordinator= (formData) => axios.post('https://7envbhrfsj.execute-api.ap-southeast-2.amazonaws.com/default/ViewGeneralChatByCoordinator', formData);
export const CountOfGeneralChatByCoordinator= (formData) => axios.post('https://s2nv4elfgd.execute-api.ap-southeast-2.amazonaws.com/default/CountOfGeneralChatByCoordinator', formData);
export const UpdateGeneralChat= (formData) => axios.post('https://s80slkv629.execute-api.ap-southeast-2.amazonaws.com/default/UpdateGeneralChat', formData);



export const listingforviewinformiep= (formData) => axios.post('https://bu8rvba5ff.execute-api.ap-southeast-2.amazonaws.com/default/listingForViewInformIEP', formData);

export const Excel_To_DB = (formData) => axios.post('https://app.tollow.com.au/excel_to_db', formData);
export const upload_student_data = (formData) => axios.post('https://app.tollow.com.au/upload_student_data', formData);
export const getgoalstep3 = (formData) => axios.post('https://k6lit4jkfk.execute-api.ap-southeast-2.amazonaws.com/default/getgoalstep3', formData);
export const review_past_iep = (formData) => axios.post('https://ht3dfdbm9a.execute-api.ap-southeast-2.amazonaws.com/default/GetIEPStep3DataByEmail', formData);
export const viewIEPStep2ByID = (formData) => axios.post('https://k41xd1wqgh.execute-api.ap-southeast-2.amazonaws.com/default/viewIEPStep2data', formData);
export const viewIEPStep3ByID = (formData) => axios.post('https://h053lp256j.execute-api.ap-southeast-2.amazonaws.com/default/viewIEPStep3data', formData);

export const Student_targetedOutcome = (formData) => axios.post('https://1bm9nt6l91.execute-api.ap-southeast-2.amazonaws.com/default/StudentTargetedOutcome', formData);

export const softSkillList = (formData) => axios.post('https://5i00m9s0k3.execute-api.ap-southeast-2.amazonaws.com/default/softSkillList', formData);

export const findEmailByParent = (formData) => axios.post('https://v84kv0e8x2.execute-api.ap-southeast-2.amazonaws.com/default/findEmailByParent', formData);



export const CreateIEPstep3Draft = (formData) => axios.post('https://lzd7t9r68f.execute-api.ap-southeast-2.amazonaws.com/default/CreateIEPstep3Draft', formData);
export const CreateKeyOutcomeDraft = (formData) => axios.post('https://r67bs20jc1.execute-api.ap-southeast-2.amazonaws.com/default/CreateKeyOutcomeDraft', formData);
export const preInformationDraft = (formData) => axios.post('https://c3kd2i3ny0.execute-api.ap-southeast-2.amazonaws.com/default/preInformationDraft', formData);
export const updateIEPStep2Draft = (formData) => axios.post('https://ivvlyephs5.execute-api.ap-southeast-2.amazonaws.com/default/updateIEPStep2Draft', formData);
export const updateIEPStep3Draft = (formData) => axios.post('https://wqvr0k2wtc.execute-api.ap-southeast-2.amazonaws.com/default/updateIEPStep3Draft', formData);
export const updateKeyOutcomeDraft = (formData) => axios.post('https://kb8b7tyqf3.execute-api.ap-southeast-2.amazonaws.com/default/updateKeyOutcomeDraft', formData);
export const viewIEPStep2Draft = (formData) => axios.post('https://x3f9xm029g.execute-api.ap-southeast-2.amazonaws.com/default/viewIEPStep2Draft', formData);
export const viewIEPStep3Draft = (formData) => axios.post('https://jpsnmy0uof.execute-api.ap-southeast-2.amazonaws.com/default/viewIEPStep3Draft', formData);

export const viewKeyOutcomeDraft = (formData) => axios.post('https://28887kuqc6.execute-api.ap-southeast-2.amazonaws.com/default/viewKeyOutcomeDraft', formData);


