import "./InformationRecords.css";
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { listingforviewinformiep } from "../../actions/studentvoicechoice";

function InformationRecords({ studentData }) {
	const dispatch = useDispatch();
	const apiData = useSelector((state) => state.studentvoicechoice);
	const user = JSON.parse(localStorage.getItem("profile"));
	console.log(studentData);
	useEffect(() => {
		dispatch(
			listingforviewinformiep({
				email: studentData.email,

				view: "student",
			})
		);
	}, []);

	const student = apiData.student;

	const [dates, setDates] = useState([
		"24-02-2023",
		"21-03-2023",
		"24-02-2023",
		"21-03-2023",
		"24-02-2023",
		"21-03-2023",
		"24-02-2023",
		"21-03-2023",
		"24-02-2023",
		"21-03-2023",
		"24-02-2023",
		"21-03-2023",
	]);
	const [dateSelected, setDateSelected] = useState();
	const [data, setData] = useState();
	// useEffect(() => {
	// 	setData(student);
	// }, [student]);
	console.log(student);
	const [activeButton, setActiveButton] = useState({
		button1: { className: "active" },
	});
	useMemo(() => {
		if (student != undefined && typeof student != "string")
			student.map((value) => {
				if (value?.date == dateSelected) setData(value?.records);
			});
	}, [dateSelected]);
	useMemo(() => {
		if (student != undefined && typeof student != "string") {
			const temp = student.map((value) => value?.date);
			setDates([...temp]);
		}
	}, [student]);
	console.log(data);
	const soft_skill_rating_meaning = [
		"Not yet",
		"Occassionally",
		"Sometimes",
		"Frequently",
		"Always",
	];
	return (
		<div className="informationRecords">
			<div>
				<h2>Conversation Record</h2>
				{/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p> */}
			</div>

			<div>
				<p className="conversation">Conversation with</p>
			</div>

			<div className="allButton">
				<button className={activeButton.button1.className}>Student</button>
			</div>

			<div className="date">
				<p>Date: </p>
				<div className="dates">
					{dates.map((value) => (
						<span
							style={{ backgroundColor: value == dateSelected && "#FFE4AF" }}
							onClick={() => setDateSelected(value)}
						>
							{value}
						</span>
					))}
				</div>

				<p>
					Record of conversation with{" "}
					<b>{studentData?.first_name + " " + studentData?.last_name}</b>
				</p>
			</div>
			<section>
				<div>
					<p className="headings">Observations / Assessment Results</p>
					<p>{data?.assessment_results}</p>
				</div>
				<div>
					<p className="headings">Barriers to Learning</p>
					<p>{data?.barriers_learning}</p>
				</div>
				<div>
					<p className="headings">Preferred Learning Styles</p>
					<p>{data?.review_learning_style}</p>
				</div>
				<div>
					<p className="headings">Strengths</p>
					<p>{data?.review_strength}</p>
				</div>
				<div>
					<p className="headings">Interests</p>
					<p>{data?.review_interest}</p>
				</div>
				<div>
					<p className="headings">Enabling Beliefs </p>
					<p>{data?.review_enabling_belief}</p>
				</div>
				{/* <div>
					<p className="headings">Limiting Beliefs </p>
					<p>{data?.review_limiting_belief}</p>
				</div> */}
				{/* <div>
					<p className="headings">Soft Skill </p>
					<div
						style={{
							display: "flex",
							alignContent: "center",
							justifyContent: "space-between",
						}}
					>
						<p className="softname">
							{eval(data?.soft_name)?.map((value) => (
								<p>{value}</p>
							))}
						</p>
						<span>
							{eval(data?.soft_skill)?.map((value) => (
								<p>{value}</p>
							))}
						</span>
					</div>
				</div> */}
				<div>
					<p className="headings">21st Century Skills </p>
					
						
							{eval(data?.soft_name)?.map((value,index) => (
								<div div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
								}}><p className="softname">{value}</p><span className="softrate">{soft_skill_rating_meaning[eval(data?.soft_skill)[index]-1]}-{eval(data?.soft_skill)[index]}</span></div>
							))}
						
						
					
				</div>
				<div>
					<p className="headings">Next Steps (including Goal Selection)</p>
					<p>
						{eval(data?.negotiate_goal)?.map((value) => (
							<p>{value}</p>
						))}
					</p>
				</div>
				<div>
					<p className="headings">Concerns Regarding Next Steps</p>
					<p>
					{eval(data?.any_concerns)?.map((value) => (
							<p>{value}</p>
						))}</p>
				</div>
				<div className="lowerHeading">
					<p className="headings ">
						What teachers can do additionally/differently
					</p>
					<p>{eval(data?.teachers_can_do_additionally)?.map((value) => (
							<p>{value}</p>
						))}</p>
				</div>
			</section>
		</div>
	);
}

export default InformationRecords;
