

import { FILESTATUS } from '../constants/actionTypes';
import * as api from '../api/index.js';
export const Excel_To_DB = (payload) => async (dispatch) => {
    try {
      const { data } = await api.Excel_To_DB(payload);
      console.log(data)
      dispatch({ type: FILESTATUS, payload: data });
    } catch (error) {
      console.log(error);
    }
  };
