
import { CloseIcon, InfoIcon } from "../../assets/Icons"
import { useEffect, useRef, useState } from "react"
import Select from "react-select"
import Switch from "react-switch"
import axios from "axios"
import { apifive } from "../../config/backend"
import  "./upload_file.css"
import { useDispatch,useSelector } from "react-redux";

import { upload_student_data } from "../../actions/assessmentrequest"

const Upload_File  = ({ setPopup,uploadData}) => {
    console.log('uploadData',uploadData)
    const dispatch = useDispatch();
    const[form,setForm]=useState({file:""})
    const filestatus=useSelector((state)=>state.auth.studentdata)
    console.log("ans check",filestatus);

//    console.log(filestatus)
   const handleSubmit = (e) => {
    console.log("true")
    e.preventDefault();
    const data=new FormData(e.target)
   
    dispatch(upload_student_data(data))
    setTimeout(()=>{alert(filestatus?.body||"Successful")},2000)
    
    setPopup(false)
     
};
const handleChange = (e) =>{
  
   setForm({ ...form, [e.target.name]: e.target.files[0] });    
    
}

    return (
        <div className="fileUpload_in">
            <div className="editdata__box_type">
            <CloseIcon onClick={() => setPopup(false)} />
                <div className="editdata__box_type_main">
                                
                <form method="post" onSubmit={handleSubmit}  enctype="multipart/form-data">
                        <input type={"text"} name="school_name" value={uploadData.school_name} hidden/>
                        <input type={"text"} name="file_name" className="text" placeholder="File Name"/>
                        <label ><p>{form.file?.name?form.file?.name:"Choose file to upload"}</p>
                            <input name="file" type={"file"} onChange={handleChange}/>
                            </label>
                        
                    
                    
                    <div className="editdata__box_type_content_val">
                        <button
                        type="submit"
                          
                            className="_1oqn"
                            
                        >
                            Save
                        </button>
                    </div>
                    </form>
                </div>
                
            </div>
        </div>
    )
}

export default Upload_File
