
export const AUTH = 'AUTH';
export const LOGIN = 'LOGOIN';
export const LOGOUT = 'LOGOUT';
export const VALIDATE = 'VALIDATE';
export const ROLES = 'ROLES';
export const SCHOOLS = 'SCHOOLS';
export const SCHOOLSCOUNT = 'SCHOOLSCOUNT';
export const USERSCOUNT = 'USERSCOUNT';
export const STUDENTSCOUNT = 'STUDENTSCOUNT';
export const TEACHERSCOUNT = 'TEACHERSCOUNT';
export const COORDINATORSCOUNT = 'COORDINATORSCOUNT';
export const SCHOOLSDETAILS = 'SCHOOLSDETAILS';
export const NEWSTUDENTCOUNT = 'NEWSTUDENTCOUNT';
export const CURRICULUM = 'CURRICULUM';
export const STUDENTASSESSMENTCOUNT = 'STUDENTASSESSMENTCOUNT';
export const SCHOOLONBOARDING = 'SCHOOLONBOARDING';
export const STUDENTDETAILS = 'STUDENTDETAILS';
export const TOTALSTUDENT = 'TOTALSTUDENT';
export const ACTIVESTUDENT = 'ACTIVESTUDENT';
export const TEACHER = 'TEACHER';
export const PARENT = 'PARENT';
export const TEACHERNAMES = 'TEACHERNAMEST';
export const FORGOTPASSWORD = 'FORGOTPASSWORD';
export const STUDENTSVOICCHOICE = 'STUDENTSVOICCHOICE';
export const TEACHERTAGGING = 'TEACHERTAGGING';
export const ASSIGNTEACHERCOORDINATOR = 'ASSIGNTEACHERCOORDINATOR';
export const COORDINATOR = 'COORDINATOR';
export const STUDENT = 'STUDENT';
export const SUPPORTSTAFF = 'SUPPORTSTAFF';
export const YEARLEVELDATA = 'YEARLEVELDATA';
export const POPULATEYEARLEVELS = 'POPULATEYEARLEVELS';
export const IEP_STEP_1_STUDENTDETAILS = 'IEP_STEP_1_STUDENTDETAILS';
export const IEP_STEP_1_IMPINFO = 'IEP_STEP_1_IMPINFO';
export const IEP_STEP_1_PERSONALINFO = 'IEP_STEP_1_PERSONALINFO';
export const ASSESSMENT_TYPE = 'ASSESSMENT_TYPE';
export const MAPSTUDENTVIEWS = 'MAPSTUDENTVIEWS';
export const TEACHERCOMMENTS = 'TEACHERCOMMENTS';
export const NEWSTUDENTDETAILS = 'NEWSTUDENTDETAILS';
export const VIEW_SUBJECT = 'VIEW_SUBJECT';
export const IEPID = 'IEPID';
export const IEP_STEP2 = 'IEP_STEP2';
export const IEP_STEP3 = 'IEP_STEP3';
export const KEY_OUTCOME = 'KEY_OUTCOME';
export const VIEW_STRATERGIES_ADJUSTMENTS = 'VIEW_STRATERGIES_ADJUSTMENTS';
export const VIEW_SOFT_SKILL = 'VIEW_SOFT_SKILL';
export const VIEW_REVIEW= 'VIEW_REVIEW';
export const VIEW_CATEGORY_OF_CONCERN= 'VIEW_CATEGORY_OF_CONCERN';
export const NEW_STUDENT_DURATION= 'NEW_STUDENT_DURATION';
export const NEGOTIATE_GOAL= 'NEGOTIATE_GOAL';
export const TEACHER_FORM= 'TEACHER_FORM';
export const USER_PROFILE_DATA= 'USER_PROFILE_DATA';
export const STUDENT_PROFILE= 'STUDENT_PROFILE';
export const UPCOMINGREVIEWS= 'UPCOMINGREVIEWS';

export const OVERDUEREVIEWS= 'OVERDUEREVIEWS';
export const VIEWALLREVIEW= 'VIEWALLREVIEW';



export const TEACHERASSESSMENTREQUEST= 'TEACHERASSESSMENTREQUEST';

export const MODIFICATIONSTUDENT= 'MODIFICATIONSTUDENT';

export const LESSON_UNIT= 'LESSON_UNIT';
export const REVIEW_PAST_IEP ='REVIEW_PAST_IEP';

export const DOCUMENT_REPOSITRY ='DOCUMENT_REPOSITRY';



export const ORGANISE_FORMAL_ASSESSMENT="ORGANISE_FORMAL_ASSESSMENT"

export const STUDENTCONVODATA="STUDENTCONVODATA"


export const BARGRAPHCOUNTS="BARGRAPHCOUNTS"


export const ALLREVIEWCOUNT="ALLREVIEWCOUNT"
export const UPCOMINGREVIEWCOUNT="UPCOMINGREVIEWCOUNT"
export const OVERDUEREVIEWCOUNT="OVERDUEREVIEWCOUNT"


export const SOFT_SKILL_SUB_CATEGORY="SOFT_SKILL_SUB_CATEGORY"

export const SOFT_SKILL_ROOT_CAUSE="SOFT_SKILL_ROOT_CAUSE"


export const SHARE_SCHOOL="SHARE_SCHOOL"

export const FILESTATUS="FILESTATUS"

export const KEY_OUTCOME_ID="KEY_OUTCOME_ID"
export const STRATEGIESADJUSTMENT="STRATEGIESADJUSTMENT"

export const VIEWKEYOUTCOME="VIEWKEYOUTCOME"

export const LESSONPLAN="LESSONPLAN"
export const PROGRESSDATA="PROGRESSDATA"
export const RECORDEVIDENCE="RECORDEVIDENCE"
export const UNITPLAN="UNITPLAN"
export const VIEWASSESSMENTRELATEDTOTARGETEDOUTCOME="VIEWASSESSMENTRELATEDTOTARGETEDOUTCOME"
export const VIEWLESSONMODIFICATIONS="VIEWLESSONMODIFICATIONS"
export const VIEWRECORDEVIDENCE="VIEWRECORDEVIDENCE"

export const CHATDATA="CHATDATA"
export const GOAL="GOAL"
export const ROLE_TEACHER = 'ROLE_TEACHER';
export const ROLE_COORDINATOR = 'ROLE_COORDINATOR';
export const ROLE_STUDENT = 'ROLE_STUDENT';
export const ROLE_SUPPORTSTAFF = 'ROLE_SUPPORTSTAFF';

export const STUDENTPARENT = 'STUDENTPARENT';



export const IEP_STEP3_DRAFT = 'IEP_STEP3_DRAFT';
export const KEY_OUTCOME_DRAFT = 'KEY_OUTCOME_DRAFT';
export const IEP_STEP2_DRAFT = 'IEP_STEP2_DRAFT';



export const RESETPASSWORD = 'RESETPASSWORD';

export const BARDETAILSTUDENTLIST = 'BARDETAILSTUDENTLIST';
export const STUDENT_DATA = 'STUDENT_DATA';


