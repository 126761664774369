import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./FormalAssessmentList.css";
import flag_black from "./../images/waving-flag-black.png";
import AssessmentRequest from "./../images/AssessmentRequest.png";
import { useSelector } from "react-redux";
import Pagination from "../Pagination/Pagination";
import {
	FacebookIcon,
	TwitterIcon,
	LinkedinIcon,
	BellIcon,
} from "../../assets/Icons";
import Header from "../Header";
import exportFromJSON from "export-from-json";
import Select from "react-select";
import { formal_assessment_list } from "../../actions/organise_formal_assessment";
import { useLocation } from "react-router-dom";
function FormalAssessmentList() {
	const location = useLocation();
	const dispatch = useDispatch();
	const user = JSON.parse(localStorage.getItem("profile"));
	const [currentPage, setCurrentPage] = useState(1);

	const history = useNavigate();

	const apiData = useSelector((state) => state?.organise_formal_assessmentReducer?.organise_formal_assessment) || []
    console.log("lookapi",apiData)
	useEffect(() => {
		dispatch(formal_assessment_list({ email: location.state?.student?.email, hgv: "fchycth" }))
	}, [])


	const count = apiData.length;
	const [search, setSearch] = useState("");
	const [isReadMore, setIsReadMore] = useState([])
	useMemo(() => {
		const temp = Array.apply(null, { length: count }).map((value) => (true))
		setIsReadMore([...temp])
	}, [count])

	let PageSize = 10;
	const currentTableData = useMemo(() => {
		const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
		return apiData && apiData.slice(firstPageIndex, lastPageIndex);
	}, [currentPage, apiData]);
	let results;

	if (search) {
		results = apiData.filter((item) => {
			let found = false;
			Object.entries(item).map(([key, value]) => {
				if (String(value).toLowerCase().includes(search.toLowerCase())) {
					found = true;
				}
			});
			return found;
		});
		results.length = PageSize;
	} else {
		results = currentTableData;
	}
	const [exportType, setExportType] = useState("csv");
	const type_options = [
		{ value: "csv", label: "csv" },
		{ value: "xls", label: "Excel" },
	];
	const fileName = "download1";
	const ExportToExcel = () => {
		var data = results
		exportFromJSON({ data, fileName, exportType });
	};
	const handleSelectChange = (value, action) => {
		setExportType(value["value"]);
	};
	const [activeView, setActiveView] = useState({

		unitplan: {
			isActive: true,
			style: { backgroundColor: "#092433", color: "white" },
		},
		lessonplan: {
			isActive: false,
			style: { color: "#092433" },
		},


	});



	return (
		<div className="teacher_card_pages">
			<Header />
			<div className="container">
				<div className="top">
					<div style={{ display: "flex" }}>
						<img src={flag_black} className="flag" />
						<p
							style={{
								marginTop: "0rem",
								fontSize: "larger",
								fontWeight: "600",
							}}
						>
							Formal Assessments
						</p>
					</div>
					<p
						style={{ marginTop: "0rem", fontSize: "larger", fontWeight: "600" }}
					>
						{count}
					</p>
				</div>
				<div className="student-search">
					<p>Quick Search</p>

					<input
						className="search-input"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						placeholder="Search"
					/>
					<div className="download-right">
						<Select
							options={type_options}
							name="date_from"
							placeholder={
								<div className="select-placeholder-text">Select file type</div>
							}
							value={exportType["value"]}
							onChange={handleSelectChange}
							required
						/>
						<button
							className="download-button"
							type="button"
							onClick={ExportToExcel}
						>
							Export
						</button>
					</div>
				</div>

				<div className="bottom">
					<div className="student-table">
						<table>
							<thead>



								<tr className="table100-head">
									<th className="column2">Specialist</th>
									<th className="column3">Scheduled Date</th>
									<th className="column4">Type Of Assessment</th>
									<th className="column6">Recommended Next Steps</th>

								</tr>

							</thead>
							{typeof (apiData) != "string" && (
								<tbody>



									{
										results?.map((apiData) => (
											<tr>
												<td class="column2">{apiData?.assessment_type && eval(apiData?.assessment_type)?.map((ele) => ele + ",")}</td>
												<td class="column3">{apiData?.assessment_date}</td>
												<td class="column4">{apiData?.assessment_kind}</td>
												<td class="column5">{apiData?.next_steps}</td>

											</tr>
										))}

								</tbody>
							)}
						</table>
					</div>
					<Pagination
						className="pagination-bar"
						currentPage={currentPage}
						totalCount={apiData?.length > 0 ? apiData?.length : 10}
						pageSize={PageSize}
						onPageChange={(page) => setCurrentPage(page)}
					/>{" "}
				</div>
			</div>
			<div className="footer">
				<FacebookIcon />
				<TwitterIcon />
				<LinkedinIcon />
			</div>
		</div>
	);
}

export default FormalAssessmentList;
