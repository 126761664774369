import React from "react";
import { Radar } from "react-chartjs-2";
import Chart from "chart.js/auto";
export const Graph = ({data}) => {
    console.log(data,"data")
    return (
		<Radar
		
							  options={{
								  
								  scales: {
									  r: {
										  grid: {
											  color: "black",
										  },
										  angleLines: {
											  color: "black",
										  },
										  beginAtZero: true,
										  min: 0,
										  max: 5,
										  pointLabels: {
											  font: {
												  size: 14,
												  weight: 700,
												  color: "black",
											  },
											  
										  },
										  ticks: {
											  display: false,
											  font: {
												  size: 20,
												  weight: 700,
												  color: "black",
												  callback: (value) => `  ${value} `,
											  },
										  },
									  },
								  },
								  ticks: {
									  stepSize: 1, // the number of step
								  },
							  }}
							  data={{
								  labels: data?.labels,
								  datasets: [
									{
									  label: "Student",
									  data: data?.student,
									  backgroundColor: "rgba(255, 255, 255, 0)",
									  borderColor: "#F05D46",
									  borderWidth: 4,
									  pointStyle: "rect",
									  radius: 10,
									  pointBackgroundColor: "#F05D46",
									},
									{
									  label: "Teacher",
									  data: data?.teacher,
									  backgroundColor: "rgba(255, 255, 255, 0)",
									  borderColor: "#69B3DD",
									  borderWidth: 4,
									  pointStyle: "rectRot",
									  radius: 10,
									  pointBackgroundColor: "#69B3DD",
									},
									{
									  label: "Carer",
									  data: data?.parent,
									  backgroundColor: "rgba(255, 255, 255, 0)",
									  borderColor: "#889159",
									  borderWidth: 4,
									  pointStyle: "rectRot",
									  radius: 10,
									  pointBackgroundColor: "#889159",
									},
								  ],
								}}
						  />
    );
  };