import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { soft_skill } from "../../actions/studentvoicechoice";
import "./SoftSkill.css";
export const SoftSkill = ({mainForm,setMainForm}) => {
	const dispatch = useDispatch();
	const [form, setForm] = useState({
		soft_skills: {
			soft_skill: [],
			subsoft_skill: [],
			root_cause: []
		},
	});
	const [soft_skill_rating,setSoftSkill]=useState([])
	useEffect(() => {
		dispatch(soft_skill());
	}, []);
	const handleChange=(e)=>{
		const temp=soft_skill_rating
		// temp.splice(form.soft_skills.root_cause.indexOf(e.target.name), 0, e.target.value);
		temp[form.soft_skills.root_cause.indexOf(e.target.name)]=Number(e.target.value)
		setSoftSkill([...temp])
		setMainForm({...mainForm,soft_skill:soft_skill_rating})
	}
	const handleSoftSkillChange = (e) => {
		const soft_skills=form.soft_skills
		const temp_soft_skill_rating=soft_skill_rating
		if(soft_skills[e.target.name].includes(e.target.value))
			{
				
				if(e.target.name=="soft_skill")
				{
					nested_list_data.map((soft_skill)=>{
						if(e.target.value==soft_skill.value)
						{
							soft_skill.Children.map((subsoft_skill)=>{
								soft_skills["subsoft_skill"].splice(soft_skills["subsoft_skill"].indexOf(subsoft_skill),1)
								subsoft_skill.Children.map((root_cause)=>{
									soft_skills["root_cause"].splice(soft_skills["root_cause"].indexOf(root_cause),1)
									
									temp_soft_skill_rating.splice(soft_skills["root_cause"].indexOf(root_cause),1)
									
								})
							})
						}


					})
				}
				if(e.target.name=="subsoft_skill")
				{
					nested_list_data.map((soft_skill)=>{
						
							soft_skill.Children.map((subsoft_skill)=>{
								if(e.target.value==subsoft_skill.value)
								{

								
								subsoft_skill.Children.map((root_cause)=>{
									soft_skills["root_cause"].splice(soft_skills["root_cause"].indexOf(root_cause),1)
									temp_soft_skill_rating.splice(soft_skills["root_cause"].indexOf(root_cause),1)
									
								})
							}})
						


					})
				}
				if(e.target.name=="root_cause")
				{
					
					temp_soft_skill_rating.splice(soft_skills.root_cause.indexOf(e.target.value),1)
					
				}
				soft_skills[e.target.name].splice(soft_skills[e.target.name].indexOf(e.target.value),1)
			}
		else
			soft_skills[e.target.name].push(e.target.value)
			if(e.target.name=="root_cause")
			{
				
				
				temp_soft_skill_rating.push("0")
				
			}
		
		setForm({ ...form, soft_skills:soft_skills });
		setSoftSkill([...temp_soft_skill_rating])
		setMainForm({...mainForm,soft_name:form.soft_skills.root_cause})
	};
	
	const apiData = useSelector(
		(state) => state.studentvoicechoice.view_soft_skill
	);
		console.log(form,soft_skill_rating)
	const nested_list_data =
		typeof apiData != "string" && apiData != undefined ? apiData : [];
		const soft_skill_rating_meaning = [
			"Not yet",
			"Occassionally",
			"Sometimes",
			"Frequently",
			"Always",
		];
	return (
		<div className="soft_skill-container">
			<p className="sub-title">21st Century Skills</p>
			{nested_list_data.map((soft_skill) => (
				<>
					<label className="soft_skill">
						<input
							type={"checkbox"}
							name="soft_skill"
							onChange={handleSoftSkillChange}
							value={soft_skill.value}
							className="checkbox"
						/>
						{soft_skill.label}
					</label>
					<br />
					{soft_skill.Children.map(
						(subsoft_skill) =>
							form.soft_skills.soft_skill.includes(soft_skill.value) && (
								<>
									<label className="subsoft_skill">
										<input
											type={"checkbox"}
											name="subsoft_skill"
											onChange={handleSoftSkillChange}
											value={subsoft_skill.value}
											className="checkbox"
										/>
										{subsoft_skill.label}
									</label>
									<br />
									{subsoft_skill.Children.map(
										(root_cause) =>
											form.soft_skills.subsoft_skill.includes(
												subsoft_skill.value
											) && (
												<div style={{display:"flex",alignContent:"center"}}>
													<label className="root_cause">
														<input
															type={"checkbox"}
															name="root_cause"
															onChange={handleSoftSkillChange}
															value={root_cause.value}
															className="checkbox"
														/>
														{root_cause.label}
						
														

													</label>
													<input
							type="range"
							min="0"
							max="5"
							defaultValue="0"
							step="1"
							className="range"
							name={root_cause.value}
							hidden={!form.soft_skills.root_cause.includes(root_cause.value) ? true : false}
							
							onChange={handleChange}
							//value={soft_skill[5]}
						/>
						<p style={{ textAlign: "center", fontSize: "12px",marginLeft: ".5rem" }}>
						{soft_skill_rating_meaning[soft_skill_rating[form.soft_skills.root_cause.indexOf(root_cause.value)] - 1]} - {soft_skill_rating[form.soft_skills.root_cause.indexOf(root_cause.value)]!="0"&&soft_skill_rating[form.soft_skills.root_cause.indexOf(root_cause.value)]}
					</p>
													<br />
												</div>
											)
									)}
								</>
							)
					)}
				</>
			))}
		</div>
	);
};
