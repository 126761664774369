import "./NotesForStudent.css";
import React, { useState, useEffect, useMemo } from "react";
import Header from "../Header"
import Footer from "../Footer"
import { Link } from "react-router-dom";
import { ArrowLeft, CalenderIcon, DownArrow, SearchIcon } from "../../assets/Icons";
import StudentInfo from "../StudentInfo";
import { useFilter } from "@promise_learning/usefilter";
import {teacherNames} from "../../actions/studentvoicechoice"
import { teachercomments,student_profile } from "../../actions/studentprofile";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Pagination from "./../Pagination/Pagination";
import { useNavigate } from "react-router-dom";

import {
	modification_for_students,
} from "./../../actions/modification_for_students";
import {Tabs} from './Tabs'

const NotesForStudent = () => {
  const history = useNavigate();  
  const dispatch = useDispatch();
  const location = useLocation();  
  const student=location.state?.student
const apiData = useSelector((state) => state.studentprofile);
const user = JSON.parse(localStorage.getItem("profile"))
const [form, setForm] = useState({
  email: location.state?.student?.email,
  teacher_email: user.email,
  school_name: user.school_name,
  category_of_concerns: "",
  notes: "",
  teacher:"",
});
useEffect(() => {
  
  dispatch(modification_for_students({ ...form, type: "view" }));
}, []);

	useEffect(() => {
	dispatch(teacherNames({ school: user.school_name }))
	
	},[])
  const teachers = useSelector((state) => state.studentvoicechoice?.teacherNames);
const modificationforstudentComment = useSelector(
  (state) => state.modificationstudent.modificationstudent
);
	useEffect(() => {
		dispatch(
		  student_profile({
			school: user?.school_name,
			email: student?.email,
		  })
		);
	  }, []);
	
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState({
		query: "",
		fields: ["first_name","last_name","notes","created_date"],
	});
  const profiles = apiData?.student_profile?.[0]
  let PageSize = 10;
  console.log(modificationforstudentComment,"modificationforstudentComment",student.email)
	const { loading, data: result } = useFilter({
		data: typeof(modificationforstudentComment)!="string"?modificationforstudentComment?.map((comments)=>comments.teacher_email == form?.teacher && comments).filter(e=>e):[],
		search: searchData,
	});
const currentTableData = useMemo(() => {
		const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
		return result?.slice(firstPageIndex, lastPageIndex);
	}, [currentPage, result]);


  const back=user?.role=="Teacher"?"/Students_list":"/AllStudentDetails"
  return (
    <div className="student-comment">
      <Header />
      <div className="student-comment__container">
        <div className="student-comment__box">
          <StudentInfo student={student}/>
          <div className="student-comment__box_profile">
            <Link to={back}>
              <ArrowLeft />
              Back to All Students
            </Link>
            <div className="student-comment__box_main">
              <div className="student-comment__box_header">
                <h1>{profiles?.first_name} {profiles?.last_name}</h1>
                
              </div>
              <div className="student-chart__header_buttons">
                <button  onClick={()=>history("/StudentProfile",{state:{student:student}})}>Student Profile</button>
                    <button onClick={()=>history("/StudentProgressChart",{state:{student:student}})}>Track Progress</button>
                    <button  onClick={()=>history("/StudentChart",{state:{student:student}})}>Map Student Views</button>
                    <button  onClick={() =>
											history("/StudentComment", {
												state: { student: student },
											})
										}>
                      {/* className="active" */}
                      Evidence Recorded
                    </button>
                    <button className="active" onClick={() =>
											history("/NotesForStudent", {
												state: { student: student },
											})
										}>
                      {/* className="active" */}
                      Notes for Student
                    </button>
                    <button onClick={() =>
											history("/ReviewPastIEP", {
												state: { student: profiles },
											})
										}>IEPs</button>
                    <button onClick={() =>
											history("/IEPDocumentRepositry", {
												state: { student: profiles },
											})
										}>Document Repository</button>
                    
                  </div>
              <div className="trackbox__container">
                <div className="header">
                  <span>Notes for Student</span>
                  <div>
                  <input
							className="search-input"
							onChange={(e) =>
								setSearchData({ ...searchData, query: e.target.value })
							}
							placeholder="Search"
							value={searchData.query}
						/>
                    
                  </div>
                </div>
                <Tabs teacher={teachers} form={form} setForm={setForm}/>
                <div className="comment_outer_container">
                <div className="comment_inner_container">
                  {typeof(currentTableData)!="string"&&currentTableData!=undefined&&
                  
                  currentTableData?.map((comments)=>
<div>
                 
                  <div className="comment_container">
                    <p>{comments?.first_name+" "+comments?.last_name} : 
                    <span>{comments?.created_date}</span>
                    </p>
                    <div className="comment">
                    <p className="comment_title"></p>
                      <p>{comments?.notes} </p>
                    </div>
                  </div>
                  <hr className="line"/>
                  </div>
                  )
                  }
                  
                 
                </div>
                </div>
                
              </div>
            </div>
          </div>
          <Pagination
						className="pagination-bar"
						currentPage={currentPage}
						totalCount={result?.length > 0 ? result?.length : 10}
						pageSize={PageSize}
						onPageChange={(page) => setCurrentPage(page)}
					/>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default  NotesForStudent;
