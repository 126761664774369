import React, {  useEffect } from "react";
import "./css/StudentInfo.css"
import {
    PageIcon,
    ReadingBook,
    SunriseIcon,
    RiskAssessment,
    PenIcon
} from "../assets/Icons"
import { useNavigate } from "react-router-dom";
import {
	roledisplay
} from "./../actions/school_systemadmin";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const StudentInfo = ({student}) => {
    const user = JSON.parse(localStorage.getItem("profile"));
    const history = useNavigate();
    const dispatch = useDispatch();
const apiData = useSelector((state) => state.school_systemadmin);
useEffect(() => {
		
			dispatch(
			roledisplay({
				role:"Teacher",
				schools: user?.school_name,
				active: "yes",
			})
		);
	}, []);
    const teacher = apiData.teacher;
    console.log(teacher,"topHeader",student)
    if(user.role!="Teacher")
    return (
        <div className="studentprofile__box_info">
            <div className="studentprofile__box_image">
                <span>{student?.first_name[0]}{student?.last_name[0]}</span>
            </div>
            <div>
                <h1>{student?.first_name} {student?.last_name}</h1>
                <span>Student No: <strong>{student?.reg_id}</strong></span>
                <span>Date of Birth:<strong>{student?.date_of_birth}</strong> </span>
                <span>Homeclass:<strong>{teacher!=undefined&&typeof(teacher)!='string'&&teacher?.map((value)=><strong>{value.first_name+" "+value.last_name+","}</strong>)} {student?.class_year}</strong> </span>
                <button>KELLY HOUSE</button>
            </div>
            {/* <div>
                <h1>Student Report</h1>
                <span>Attendance: <strong>Consistent</strong></span>
                <span>Effort: <strong>Consistent</strong></span>
            </div> */}
            <div>
                <h1>Learning Plan</h1>
                <span>Status: <strong style={{textTransform:"capitalize"}}>{ student?.iep}</strong></span>
                <span>No. of Adjustments: <strong>N/A</strong></span>
                <span>Support Level: <strong>N/A</strong></span>
                <span>Review Date: <strong>{ student?.review_date}</strong></span>
            </div>
            <div className="studentprofile__box_links">
                <div>
                    <PageIcon />
                    <span onClick={()=>history("/IndividualAssessmentRequest",{state:{student:student,access:"edit"}})}>Assessment Request</span>
                </div>
                <div >
                    <ReadingBook />
                    <span onClick={()=>history("/StudentProfile",{state:{student:student}})}>View Student Profile</span>
                </div>
                <div>
                    <SunriseIcon />
                    <span onClick={()=>history("/StudentVoiceChoice",{state:{student:student}})}>Student Voice & Choice</span>
                </div>
                <div>
                    <SunriseIcon />
                    <span onClick={()=>history("/ConversationTeacherParent",{state:{student:student}})}>Teacher/Carer Views</span>
                </div>
               
                      <div>
                    <RiskAssessment />
                    <span onClick={()=>history("/FormalAssessmentList",{state:{student:student}})}>Formal Assessment View</span>
                </div>
                <div>
                    <RiskAssessment />
                    <span onClick={()=>history("/OrganiseFormalAssessment",{state:{student:student}})}>Formal Assessment</span>
                </div>
                <div>
                    <PenIcon />
                    <span onClick={()=>history("/IEP",{state:{student:student}})}>Create IEP</span>
                </div>
            </div>
        </div>
    )
}

export default StudentInfo
