import * as actionType from "../constants/actionTypes";

const general_chatReducer = (
	state = { general_chatData: null },
	action
) => {
	switch (action.type) {

		case actionType.CHATDATA:
			return { ...state, chatdata: JSON.parse(action.payload)?.body };
		default:
			return state;
	}
};

export default general_chatReducer;