import "./View.css";
import React, { useState, useEffect,useMemo } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
	informationRecords
} from "../../actions/studentvoicechoice";
import { Goal } from "./Goal";
function ParentView(data) {
	const dispatch = useDispatch();
	const activeTab = data.tab;
	const apiData = data.apiData;
	const handleSubmit = data.handleSubmit;
	const publish = data.publish;
	const student = data.student;
	const soft_skill_options=data.soft_skill_options!=undefined&&eval(data.soft_skill_options)
	const date=new Date()
	const user = JSON.parse(localStorage.getItem("profile"))
	const studentForm=useSelector((state)=>state.studentprofile?.student?.slice(-1)[0])
	const dateFormat = (date) => {
		return (
			date?.split("-")[2] +
			"-" +
			date?.split("-")[1] +
			"-" +
			date?.split("-")[0]
		);
	};
	const [form, setForm] = useState({
		user_id:"",
		email: student?.email,
		school_name: user.school_name,
		assessment_results: studentForm?.assessment_results,
		barriers_learning: "",
		review_learning_style: "",
		review_strength: "",
		review_interest: "",
		review_limiting_belief: "",
		review_enabling_belief: "",
		soft_name: soft_skill_options,
		soft_skill: [],
		negotiate_goal:  eval(studentForm?.negotiate_goal)||[""],
		how_goals_can_be_achieved: "",
		any_concerns: "",
		teachers_can_do_additionally: "",
		view: "parent",
		question: "",
		answer: "",
		created_date: dateFormat(studentForm?.created_date),
		tab: "inform",
	});
	const [publishData, setPublishData] = useState({
		email: student?.email,
		teachers: [],
		view: "parent",
	});
	const teacherCheckbox = [];
	for (var i = 0; i < 6; i++) {
		teacherCheckbox.push(false);
	}
	const [checked, setChecked] = useState({
		review_learning_style: true,
		review_strength: true,
		review_interest: true,
		review_limiting_belief: true,
		review_enabling_belief: true,
		

		teacher: teacherCheckbox,
	});
	
	const [teachers, setTeachers] = useState([]);
	const handleChange = (e) => {
		
			setForm({ ...form, [e.target.name]: e.target.value });
		
		if (e.target.name == "created_date")
			dispatch(
				informationRecords({
					email: student?.email,
					school: user.school_name,
					created_date: e.target.value,
					view: "parent",
				})
			);
	};
	const handleRatingChange= (e,index) => {
		
			const temp = form.soft_skill;
			temp[index] = Number(e.target.value);
			
			setForm({
				...form,
				
				soft_skill: temp,
			});
		
	}
	useEffect(()=>{
		

		dispatch(
			informationRecords({
				email: student?.email,
				school: user.school_name,
				created_date: date.getDate(),
				view: "parent",
			})
		);
	},[])
	const prevData = useSelector((state) => state.studentvoicechoice?.parent?.[0]);
	
	useMemo(() => {
		if (prevData != undefined && typeof( prevData) != "string")
			{	
				const temp=prevData.soft_skill?.split(',')?.map((value)=>Number(value))||[]

				setForm({
					...form,
				...prevData,
				negotiate_goal: eval(prevData.negotiate_goal)||[""],
				how_goals_can_be_achieved: eval(prevData.how_goals_can_be_achieved)||[""],
				any_concerns: eval(prevData.any_concerns)||[""],
				teachers_can_do_additionally: eval(prevData.teachers_can_do_additionally)||[""],
				soft_name: eval(prevData.soft_name)||[""],
				soft_skill:temp,
				answer:"",
				question:"",
				view:"parent"
			});
			
			
		}
		if(typeof( prevData) == "string"||prevData == undefined)
		setForm({
			user_id:"",
		email: student?.email,
		school_name: user.school_name,
		assessment_results:studentForm?.assessment_results,
		barriers_learning: "",
		review_learning_style: "",
		review_strength: "",
		review_interest: "",
		review_limiting_belief: "",
		review_enabling_belief: "",
		soft_name: soft_skill_options,
		soft_skill: [""],
		negotiate_goal:  eval(studentForm?.negotiate_goal)||[""],
		how_goals_can_be_achieved: [""],
		any_concerns: [""],
		teachers_can_do_additionally: [""],
		view: "parent",
		question: "",
		answer: "",
		tab: "inform",
		created_date: dateFormat(studentForm?.created_date),
		});
		
	}, [prevData]);
	const handleGoalChange = (e, index) => {
		const temp = form?.[e.target.name];
		temp[index] = e.target.value;
		setForm({ ...form, [e.target.name]: temp });
		
	};
	useMemo(() => {
		
		setForm({
			...form,
			negotiate_goal:  eval(studentForm?.negotiate_goal)||[""],
			assessment_results:studentForm?.assessment_results,
			tab: activeTab.tab1.isActive == "block" ? "inform" : "review",
			soft_name: data.soft_skill_options!=undefined&&eval(data.soft_skill_options),
		});
		
	}, [data.soft_skill_options,studentForm]);
	const soft_skill_rating_meaning = [
		"Not yet",
		"Occassionally",
		"Sometimes",
		"Frequently",
		"Always",
	];
	
	
	
	return (
		<div className="teacher-parent-view">
			<div
				className="inform-iep-development"
				style={{ display: activeTab.tab1.isActive }}
			>
				<form onSubmit={(e) => handleSubmit(e, form)}>
					<p className="title">Conversation to Develop IEP </p>
					<div className="question">
						<p className="sub-title">
							Date:{" "}
							<input
								type={"date"}
								className="date"
								name="created_date"
								value={form?.created_date}
								onChange={handleChange}
							/>
							{/* <span>{date.toLocaleDateString()}</span> */}
						</p>
					</div>
					<div className="question">
						<p>Discuss observations / assessment results</p>
						<input
							type={"text"}
							className="text"
							onChange={handleChange}
							placeholder="Please enter notes and comments"
							name="assessment_results"
							value={form?.assessment_results}
						/>
					</div>
					<br />
					<div className="question">
						<p>What are the barriers to learning?</p>
						<input
							type={"text"}
							className="text"
							onChange={handleChange}
							value={form?.barriers_learning}
							placeholder="Please enter notes and comments"
							name="barriers_learning"
						/>
					</div>
					<br />

					<p>Review</p>
					<label>
						<div className="review">
							<input
								type={"checkbox"}
								className="checkbox"
								onChange={() => {
									setChecked({
										...checked,
										review_learning_style: !checked.review_learning_style,
									});
									//setForm({ ...form, review_learning_style: "" });
								}}
							/>
							<p>
							Preferred Learning Styles
							</p>
							<input
								type={"text"}
								className="text"
								placeholder="Please Specify"
								name="review_learning_style"
								id="review_learning_style"
								//disabled={checked.review_learning_style}
								onChange={handleChange}
								value={form.review_learning_style}
							/>
						</div>
					</label>
					<br />
					<label>
						<div className="review">
							<input
								type={"checkbox"}
								className="checkbox"
								onChange={() => {
									setChecked({
										...checked,
										review_strength: !checked.review_strength,
									});
									//setForm({ ...form, review_strength: "" });
								}}
							/>
							<p>Strengths</p>
							<input
								type={"text"}
								className="text"
								placeholder="Please Specify"
								name="review_strength"
								//disabled={checked.review_strength}
								onChange={handleChange}
								value={form.review_strength}
							/>
						</div>
					</label>
					<br />
					<label>
						<div className="review">
							<input
								type={"checkbox"}
								className="checkbox"
								onChange={() => {
									setChecked({
										...checked,
										review_interest: !checked.review_interest,
									});
									//setForm({ ...form, review_interest: "" });
								}}
							/>
							<p>Interests</p>
							<input
								type={"text"}
								className="text"
								placeholder="Please Specify"
								name="review_interest"
								//disabled={checked.review_interest}
								onChange={handleChange}
								value={form.review_interest}
							/>
						</div>
					</label>
					<br />
					<label>
						<div className="review">
							<input
								type={"checkbox"}
								className="checkbox"
								onChange={() => {
									setChecked({
										...checked,
										review_limiting_belief: !checked.review_limiting_belief,
									});
									//setForm({ ...form, review_limiting_belief: "" });
								}}
							/>
							<p>Limiting Beliefs </p>
							<input
								type={"text"}
								className="text"
								placeholder="Please Specify"
								name="review_limiting_belief"
								//disabled={checked.review_limiting_belief}
								onChange={handleChange}
								value={form.review_limiting_belief}
							/>
						</div>
					</label>
					<br />
					<label>
						<div className="review">
							<input
								type={"checkbox"}
								className="checkbox"
								onChange={() => {
									setChecked({
										...checked,
										review_enabling_belief: !checked.review_enabling_belief,
									});
									//setForm({ ...form, review_enabling_belief: "" });
								}}
							/>
							<p>Enabling Beliefs </p>
							<input
								type={"text"}
								className="text"
								placeholder="Please Specify"
								name="review_enabling_belief"
								//disabled={checked.review_enabling_belief}
								onChange={handleChange}
								value={form.review_enabling_belief}
							/>
						</div>
					</label>
					<br />
					<br />
					<br />
					<p>Soft Skills</p>
					
					{soft_skill_options&&soft_skill_options!=undefined&&typeof(soft_skill_options)!="string"&&
					soft_skill_options?.map((value,index)=>(
						<div className="soft_skill">
					<p>{value}</p>
							
						<input
							type="range"
							min="0"
							max="5"
							defaultValue="0"
							step="1"
							className="range"
							name="rating"
							
							onChange={(e)=>handleRatingChange(e,index)}
							value={form.soft_skill[index]||0}
						/>
						<div>
							<p style={{ textAlign: "center", fontSize: "12px",marginLeft:"1rem" }}>
								{soft_skill_rating_meaning[form.soft_skill[index] - 1]} - {form.soft_skill[index]!=0&&form.soft_skill[index]}
							</p>
							
						</div>
					</div>
					))}
					

					
					<br />
					<br />
					<br />

					<Goal form={form} setForm={setForm} handleChange={handleChange} handleGoalChange={handleGoalChange}/>
					<span
						style={{
							color: "white",
							fontSize: "14px",
							fontWeight: "700",
							textAlign:"center",
							display:"block",
							width:"fit-content",
							backgroundColor:"#FCB730",
							margin:"0 auto",
							border:"none",
							borderRadius:"1rem",
							padding:".5rem 1.5rem"
						}}
						onClick={() => {
							const temp = form?.negotiate_goal;
							temp.push("");
							setForm({ ...form, negotiate_goal: temp });
						}}
					>
						Add Goal
					</span>
					<div className="buttons">
						<div className="left">
							<button className="save-continue" type="submit">
								Save
							</button>
							{/* <button className="back">Back</button> */}
						</div>
						<div className="right">
							<button
								className="publish"
								onClick={(e) => {
									publish(e, publishData);
								}}
							>
								Publish
							</button>
						</div>
					</div>
				</form>
			</div>
			<div className="review-iep" style={{ display: activeTab.tab2.isActive }}>
				<form onSubmit={(e) => handleSubmit(e, form)}>
					<p className="title">Conversation to Review IEP</p>
					<div className="question">
						<p>Revisit goals</p>
						<input
							type={"text"}
							className="text"
							onChange={handleChange}
							placeholder="Please enter notes and comments"
							name="revisit_goal"
						/>
					</div>
					<br />
					<div className="question">
						<p>Discuss observations / assessment results</p>
						<input
							type={"text"}
							className="text"
							onChange={handleChange}
							placeholder="Please enter notes and comments"
							name="assessment_result"
						/>
					</div>
					<br />
					<div className="question">
						<p>Can you provide examples?</p>
						<input
							type={"text"}
							className="text"
							onChange={handleChange}
							placeholder="Please enter notes and comments"
							name="examples"
						/>
					</div>
					<br />

					<div className="question">
						<p>What else can we do to achieve these goals? </p>
						<input
							type={"text"}
							className="text"
							placeholder="Please enter notes and comments"
							name="achieve_goals"
							onChange={handleChange}
						/>
					</div>
					<br />
					<div className="question">
						<p>Discuss any concerns</p>
						<input
							type={"text"}
							className="text"
							placeholder="Please enter notes and comments"
							name="any_conserns"
							onChange={handleChange}
						/>
					</div>
					<br />

					<div className="question">
						<p>
							Explore whether teacher/s can do something additionally or
							differently.
						</p>
						<input
							type={"text"}
							className="text"
							placeholder="Please enter notes and comments"
							name="teachers_can_do_additionally"
							onChange={handleChange}
						/>
					</div>
					<br />
					<div className="buttons">
						<div className="left">
							<button className="save-continue" type="submit">
								Save
							</button>
							
						</div>
						<div className="right">
							<button className="publish" onClick={publish}>
								Publish
							</button>
						</div>
					</div>
				</form>
			</div>
			<label>
				<input
					type={"checkbox"}
					className="checkbox"
					name="tag_all_teachers"
					onChange={(e) => {
						const temp = checked.teacher.map(function (bool) {
							return !bool;
						});

						setChecked({ ...checked, teacher: temp });
						setPublishData({
							...publishData,
							teachers: apiData.teacherNames?.map((data, index) =>
								temp[index] ? data.email : ""
							),
						});
					}}
				/>
				Tag all teachers
			</label>
			<div className="teachers">
				{apiData.teacherNames?.map((value, index) => (
					<label>
						<input
							type={"checkbox"}
							className="checkbox"
							name="teacher"
							value={value.email}
							checked={checked.teacher[index]}
							onChange={(e) => {
								const temp = checked.teacher;
								temp[index] = !checked.teacher[index];
								setChecked({ ...checked, teacher: temp });
								if (
									!teachers.includes(e.target.value) &&
									checked.teacher[index]
								) {
									const temp = publishData.teachers;
									temp.push(e.target.value);
									setPublishData({ ...publishData, teachers: temp });
								} else {
									const temp = publishData.teachers;
									temp.splice(temp.indexOf(e.target.value), 1);

									setPublishData({ ...publishData, teachers: temp });
								}
							}}
						/>
						{value.first_name + " " + value.last_name}
					</label>
				))}
			</div>
		</div>
	);
}

export default ParentView;
