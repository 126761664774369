import React,{useState,useMemo,useEffect} from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
export const Graph = ({data,color,startDate,endDate}) => {
	
    const [labels,setLabels]=useState()
	const [graphData,setGraphData]=useState()
	const dateFormat = (date) => {
		return (
			date?.split("-")[2] +
			"-" +
			date?.split("-")[1] +
			"-" +
			date?.split("-")[0]
		);
	};
	useEffect(()=>{
		setGraphData([...data.values])
		setLabels([...data.date_list])
	},[data])
	useMemo(()=>{
		if(startDate!=undefined&&endDate!=undefined)
		{
			const templabels=[...data.date_list]
			const tempdata=[...data.values]
			data.date_list.map((value,index)=>{
				console.log(dateFormat(value)<startDate,dateFormat(value)>endDate)
				console.log("value",dateFormat(value))
				console.log("startDate",startDate)
				console.log("endDate",endDate)
				if(dateFormat(value)<startDate||dateFormat(value)>endDate)
				{
					templabels.splice(templabels.indexOf(value), 1);
					tempdata.splice(templabels.indexOf(value), 1);
				}
			})
			setLabels([...templabels])
			setGraphData([...tempdata])
			console.log("1st")
		}
		else if(startDate!=undefined)
		{
			
			const templabels=[...data.date_list]
			const tempdata=[...data.values]
			data.date_list.map((value,index)=>{
				
				if(dateFormat(value)<startDate)
				{
					templabels.splice(templabels.indexOf(value), 1);
					tempdata.splice(templabels.indexOf(value), 1);
				}
			})
			setLabels([...templabels])
			setGraphData([...tempdata])
			console.log("2nd")
			
		}
		
		else if(endDate!=undefined)
		{
			const templabels=[...data.date_list]
			const tempdata=[...data.values]
			data.date_list.map((value,index)=>{
				if(dateFormat(value)>endDate)
				{
					templabels.splice(templabels.indexOf(value), 1);
					tempdata.splice(templabels.indexOf(value), 1);
				}
			})
			setLabels([...templabels])
			setGraphData([...tempdata])
			console.log("3rd")
			
		}
		if(startDate==undefined&&endDate==undefined)
		{
			setLabels([...data.date_list])
			setGraphData([...data.values])
		}
	},[startDate,endDate])
	console.log("data.percent",data)
	
    return (
      <Line
							data={{
								// Name of the variables on x-axies for each bar
								labels: labels,//data.percent?["10%","20%","30%","40%","50%","60%","70%","80%","90%","100%"]:["1","2","3","4","5","6","7","8","9","10"],
								datasets: [
									{
										// Label for bars
                    label:"",
										// Data or value of your each variable
										data: graphData,
										// Color of each bar
										backgroundColor: [
											color,
											
										],
										// Border color of each bar
										borderColor: [
											color,
											
										],
										borderWidth: 2,
										
										
									},
								],
							}}
							// Height of graph
							height={400}
							width={100}
							options={{
								plugins: {
									legend: { display: false },
								},

								responsive: true,
								maintainAspectRatio: false,
								scales: {
									x: {
										grid: {
											display: false,
										},
									},
									y: {
                    beginAtZero: true,
										grid: {
											display: false,
										},
										min: 1,
    max: data.percent=="1"?100:10,
										ticks: {
											stepSize: 1,
                      
										},
									},
								},
							}}
						/>
    );
  };