import "./PopUp.css"
import { CloseIcon, InfoIcon } from "../../assets/Icons"
import "react-phone-input-2/lib/style.css";
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { forgotpassword,resetpassword } from "../../actions/auth";
const SuccessPopUp = ({ setPopup, popup, setIsSignup }) => {


	const history = useNavigate();
	const [username, setUsername] = useState("")
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
	const [error, setError] = useState('');
	const [currpasstype,setPasswordType]=useState("password")
	const [newpasstype,setnewPasswordType] = useState("password")
	const [formdata, setFormdata] = useState({});
    // const [error, setError] = useState('');
	const loginRedirect = () => {
		setPopup(false)
		setIsSignup(false)
		history("/")
	}
	// const redirecttoForget = () => {
	// 	history('/Forgetpassword')
	// }
	const message = useSelector((state) => state?.auth?.newPassword)
	console.log("message",message)
	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(forgotpassword({ username: username }, history));

	};
	
	const handleResetPassword = (e) => {
		e.preventDefault();
	
		// Basic frontend validation
		if (!email || !currentPassword || !newPassword || !confirmNewPassword) {
		  setError('Please fill in all fields.');
		  return;
		}
	
		if (newPassword !== confirmNewPassword) {
		  setError('New password and Confirm new password do not match.');
		  return;
		}
		const datavalue =  {
			email,
			currentPassword,
			newPassword,
		  }
		  console.log(datavalue);	
		setFormdata(datavalue)
		 //  dispatch(ui_logs({page:"Reset Page",status:"Reset Password"})
	};

	  useEffect(()=>{
		dispatch(resetpassword(formdata));
	   },[formdata])


	return (
		<div className="login">
			<div className="popup__container">
				<div className="popup__box">
					<CloseIcon onClick={() => {
						setPopup(false)
						message.Status = ""
					}} />
					<br />
					<div className="popup__box_main" >
						{(popup.type == "success") && (<><p >User Registered</p>


							<div className="userdetails__settings">

								<button onClick={loginRedirect}

									className="settings__button"
								>
									Click here to login
								</button>
							</div></>)}
						{(popup.type == "forgetpassword") && (<><form onSubmit={handleSubmit}>

							<div class="inputbox">
								<input
									type={"text"}
									name="username"
									placeholder={"Email address"}
									onChange={e => setUsername(e.target.value)}
									required
									autoComplete="off"
								/>
							</div>
							<p
								style={{
									marginTop: "-2rem",
									textAlign: "center",
									fontSize: "12px",
									color: "red",
								}}
							>
								{message?.Status}
							</p> 

							<div className="userdetails__settings">
								<button type="submit" className="settings__button">
								  Send Email
								</button></div>
						</form></>)}

						{(popup.type == "resetpassword") && (<><form className="reset-font-size" onSubmit={handleResetPassword}>
						{error && <div>{error}</div>}
							<div className="style-reset">
						    <label className="font-size">Email:</label>
                                <input type="email" className="input-style" placeholder={"Email"} value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
							<div className="style-reset">
                               <label className="font-size">Current Password:</label>
                                <input type={currpasstype}  className="input-style" placeholder={"Current password"} value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
								<i className="bi bi-eye-slash" id="togglePassword"  style={{marginLeft:"-1rem"}} onClick={(e)=>{if(currpasstype=="password")
										{setPasswordType("text")
											e.target.className="bi-eye"}
										else
										{setPasswordType("password")
										e.target.className="bi bi-eye-slash"
									    }
										}}>
								</i>
							</div>
							<div className="style-reset">
								<label className="font-size">New Password:</label>
								<input type={newpasstype} className="input-style" placeholder={"New password"} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
								<i className="bi bi-eye-slash" style={{marginLeft:"-1rem"}} id="togglePassword" onClick={(e)=>{if(newpasstype=="password")
										{setnewPasswordType("text")
											e.target.className="bi-eye"}
										else
										{setnewPasswordType("password")
										e.target.className="bi bi-eye-slash"
									    }
										}}></i>
							</div>
							<div className="style-reset">
								<label className="font-size">Confirm New Password:</label>
								<input type="password" className="input-style" placeholder={"Confirm new password"} value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
							</div>
							{/* <button type="submit">Reset Password</button> */}
							<p
								style={{
									textAlign: "center",
									fontSize: "12px",
									color: "red",
								}}
							>
								{message?.statusCode=="200"&&"Password Changed successfully"}
							</p>

							<div className="userdetails__settings">
								<button type="submit" className="settings__button" style={{marginTop:'3rem'}} >
								  Reset Password
								</button></div>
						</form></>)}
					</div>
				</div>
			</div></div>
	)
}

export default SuccessPopUp