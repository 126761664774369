import { CHATDATA } from "../constants/actionTypes";
import * as api from '../api/index.js';



  export const CreateGeneralChat = (payload) => async (dispatch) => {
    
    try {
      const { data } = await api.CreateGeneralChat(payload);
     
        
    } catch (error) {
      console.log(error);
    }
  };
  export const UpdateGeneralChat = (payload) => async (dispatch) => {
    
    try {
      const { data } = await api.UpdateGeneralChat(payload);
     
        
    } catch (error) {
      console.log(error);
    }
  };

  export const ViewGeneralChatByTeacher = (payload) => async (dispatch) => {
    
    try {
      const { data } = await api.ViewGeneralChatByTeacher(payload);
      dispatch({ type: CHATDATA, payload: data });
        
    } catch (error) {
      console.log(error);
    }
  };
  export const ViewGeneralChatByCoordinator = (payload) => async (dispatch) => {
    
    try {
      const { data } = await api.ViewGeneralChatByCoordinator(payload);
      dispatch({ type: CHATDATA, payload: data });
        
    } catch (error) {
      console.log(error);
    }
  };
  export const CountOfGeneralChatByCoordinator = (payload) => async (dispatch) => {
    
    try {
      const { data } = await api.CountOfGeneralChatByCoordinator(payload);
      console.log(data)
      dispatch({ type: CHATDATA, payload: data });
        
    } catch (error) {
      console.log(error);
    }
  };