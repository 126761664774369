
import React, { useState } from "react";
import group from "./../images/Mask Group.png";
import "./ExcelToDB.css";
import UploadFile from "./uploadFile";
import category_of_concerns_format from "./templates/category_of_concerns_format.xlsx"
import soft_skills_format from "./templates/soft_skills_format.xlsx"
import strategies_adjustments_format from "./templates/strategies_adjustments_format.xlsx"
import subject_requiring_adjustment_format from "./templates/subject_requiring_adjustment_format.xlsx"
import user_info_format from "./templates/user_info_format.xlsx"
import assessment_request_format from "./templates/assessment_request_format.xlsx"
import Header from "../Header";
import Footer from "../Footer";

function ExcelToDB() {
	const [popup, setPopup] = useState(false);
	const [uploadData, setUploadData] = useState();
    const user = JSON.parse(localStorage.getItem("profile"))
	return (
		<div class="exceltodb">
			<Header />
			{popup ? (
				<UploadFile setPopup={setPopup} uploadData={uploadData} />
			) : undefined}
			<div class="container">
				<div class="top">
					<div class="welcome">
						<p
							style={{
								fontSize: "1.2rem",
								fontWeight: "700",
								color: "#F05D46",
							}}
						>
							Welcome back {user?.first_name}!
						</p>
						<p
							style={{ fontSize: "medium", fontWeight: "600", color: "#092433" }}
						>
							Please go through the instructions carefully before uploading any file.
						</p>
					</div>
					<img src={group} class="group" />
				</div>
				<div class="bottom">
                    <div className="upload-block">
                        <p className="title">Upload User Info</p>
                        <p className="subtitle">Instructions:</p>
                        <ol className="instruction">
                        <li>Students are considered as users, so please include student info while filling the template.</li>
                            <li>Columns like 'class_year' and 'date_of_birth' are applicable only for students.</li>
                            <li>Column 'role' can have values like "Student","Teacher","Coordinator","Support Staff","Care Giver" and "Parents".</li>
                            <li>Column 'role' is case sensitive so please use the exact keywords mentioned in previous step.</li>
                            <li>Column 'active' can have only two values "yes" or "no". This is also case sensitive, so make sure to use the same keyword.</li>
                            <li>Column 'school_name' should have the same value which was used during School Onboarding.</li>
                            <li>Columns 'IEP' is applicable only for teachers and can have only two values, "yes" or "no". If value is "yes" then that teacher can create IEP.</li>
                            <li>Since we will automate feeding the data to the database, ensure all the data entered in this template is correct(i.e no extra spaces and no spelling errors). Also do not use any cell formatting (i.e do not merge cells or fill colours).</li>
							<li>Repeating Data will create repeated entries in database</li>
                        </ol>
                        <div className="action_buttons">
                        <a className="button download" href={user_info_format} download="user_info.xlsx">Download Template</a>
                        <button
						className="button upload"
						onClick={() => {
							setPopup(true);
							setUploadData({
								table_name: "user_info",
							});
						}}
					>
						Upload File
					</button></div>
                    </div>
					<div className="upload-block">
                        <p className="title">Upload Student Info</p>
                        <p className="subtitle">Instructions:</p>
                        <ol className="instruction">
                        <li>Students are considered as users, so please include only those students whose data is already filled in user info template.</li>
                            <li>Columns like 'first_name','last_name','email','class_year' and 'date_of_birth' should have the excat same value as user info.</li>
                            <li>Column 'learning_coordinator' , "Teacher" and "Parent" can be used to map the student to a coordinator and a teacher.</li>
                            <li>Column 'learning_coordinator', "Parent" and "Teacher" take email of the user as value.</li>
							<li>Parents are considered as users, so please include only those Parents whose data is already filled in user info template.</li>
							<li>Column "Teacher" : Email IDs should be enclosed in single quotes, separated by coma and should be inside a square bracket. Ex: ['teacher1@gmail.com','teacher2@gmail.com',...] .</li>
                            <li>Column 'learning_coordinator' and "Teacher" are not mandatory and can be left empty.</li>
                            <li>Since we will automate feeding the data to the database, ensure all the data entered in this template is correct(i.e no extra spaces and no spelling errors). Also do not use any cell formatting (i.e do not merge cells or fill colours).</li>
							<li>Repeating Data will create repeated entries in database</li>
                        </ol>
                        <div className="action_buttons">
                        <a className="button download" href={assessment_request_format} download="student_info.xlsx">Download Template</a>
                        <button
						className="button upload"
						onClick={() => {
							setPopup(true);
							setUploadData({
								table_name: "assessment_request",
							});
						}}
					>
						Upload File
					</button></div>
                    </div>
                    <div className="upload-block">
                        <p className="title">Upload Category of concerns</p>
                        <p className="subtitle">Instructions:</p>
                        <ol className="instruction">
						<li>Column 'class_year' should have value in the same format as user info.</li>
                            <li>Column 'curriculum_name' can have the value mentioned in Curriculum dropdown.</li>
                            <li>Column 'curriculum_name' is case sensitive, hence use the exact keyword mentioned in previous point.</li>
                            <li>Since we will automate feeding the data to the database, ensure all the data entered in this template is correct(i.e no extra spaces and no spelling errors). Also do not use any cell formatting (i.e do not merge cells or fill colours).</li>
							<li>Repeating Data will create repeated entries in database</li>
                        </ol>
                        <div className="action_buttons">
                        <a className="button download" href={category_of_concerns_format} download="category_of_concern.xlsx">Download Template</a>
                        <button
						className="button upload"
						onClick={() => {
							setPopup(true);
							setUploadData({
								table_name: "category_of_concern",
							});
						}}
					>
						Upload File
					</button></div>
                    </div>
					<div className="upload-block">
                        <p className="title">Upload Subjects</p>
                        <p className="subtitle">Instructions:</p>
                        <ol className="instruction">
                      
                            <li>Column 'school_name' should have the same value which was used during School Onboarding.</li>
                           
                            <li>Since we will automate feeding the data to the database, ensure all the data entered in this template is correct(i.e no extra spaces and no spelling errors). Also do not use any cell formatting (i.e do not merge cells or fill colours).</li>
							<li>Repeating Data will create repeated entries in database</li>
                        </ol>
                        <div className="action_buttons">
                        <a className="button download" href={subject_requiring_adjustment_format} download="subject_requiring_adjustment_format.xlsx">Download Template</a>
                        <button
						className="button upload"
						onClick={() => {
							setPopup(true);
							setUploadData({
								table_name: "subject_requiring_adjustment",
							});
						}}
					>
						Upload File
					</button></div>
                    </div>
                    <div className="upload-block">
                        <p className="title">Upload Strategies Adjustment</p>
                        <p className="subtitle">Instructions:</p>
                        <ol className="instruction">
                      
                            <li>Column 'school_name' should have the same value which was used during School Onboarding.</li>
                           
                            <li>Since we will automate feeding the data to the database, ensure all the data entered in this template is correct(i.e no extra spaces and no spelling errors). Also do not use any cell formatting (i.e do not merge cells or fill colours).</li>
							<li>Repeating Data will create repeated entries in database</li>
                        </ol>
                        <div className="action_buttons">
                        <a className="button download" href={strategies_adjustments_format} download="strategies_adjustments.xlsx">Download Template</a>
                        <button
						className="button upload"
						onClick={() => {
							setPopup(true);
							setUploadData({
								table_name: "strategies_adjustments",
							});
						}}
					>
						Upload File
					</button></div>
                    </div>
                    <div className="upload-block">
                        <p className="title">Upload Soft Skills</p>
                        <p className="subtitle">Instructions:</p>
                        <ol className="instruction">
                      
                            
                           
                            <li>Since we will automate feeding the data to the database, ensure all the data entered in this template is correct(i.e no extra spaces and no spelling errors). Also do not use any cell formatting (i.e do not merge cells or fill colours).</li>
							<li>Repeating Data will create repeated entries in database</li>
                        </ol>
                        <div className="action_buttons">
                        <a className="button download" href={soft_skills_format} download="soft_skills_format.xlsx">Download Template</a>
                        <button
						className="button upload"
						onClick={() => {
							setPopup(true);
							setUploadData({
								table_name: "soft_skill",
							});
						}}
					>
						Upload File
					</button></div>
                    </div>
                    
                    
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default ExcelToDB;
