import "./StudentChart.css";
import React, { useState,useRef, useEffect, useMemo } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import {
	ArrowLeft,
	CalenderIcon,
	DownArrow,
	SearchIcon,
} from "../../assets/Icons";
import StudentInfo from "../StudentInfo";
import {
	Chart as ChartJS,
	RadialLinearScale,
	PointElement,
	LineElement,
	Filler,
	Tooltip,
	Legend,
} from "chart.js";

import { mapstudentviews,student_profile } from "../../actions/studentprofile";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import ReactToPrint from "react-to-print";
import { Graph } from "./Graph";
ChartJS.register(
	RadialLinearScale,
	PointElement,
	LineElement,
	Filler,
	Tooltip,
	Legend
);



const StudentChart = () => {
	const componentRef = useRef();
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useNavigate();
	const studentDat = location.state?.student;

	const user = JSON.parse(localStorage.getItem("profile"))
	const apiData = useSelector((state) => state.studentprofile);
	useEffect(() => {
		dispatch(
			mapstudentviews({
				email: studentDat?.email,
				school_name: user.school_name,
				active: "yes",
				view: "student",
			})
		);
		dispatch(
			mapstudentviews({
				email: studentDat?.email,
				school_name: user.school_name,
				active: "yes",
				view: "parent",
			})
		);
		dispatch(
			mapstudentviews({
				email: studentDat?.email,
				school_name: user.school_name,
				active: "yes",
				view: "teacher",
			})
		);
	}, []);
	useEffect(() => {
		dispatch(
		  student_profile({
			school: user?.school_name,
			email: studentDat?.email,
		  })
		);
	  }, []);
	const student = apiData.student;
	const teacher = apiData.teacher;
	const parent = apiData.parent;
	const mapstudentviewsData = apiData?.student;
	const [dates,setDates]=useState([])
	const [dateSelected, setDateSelected] = useState();
	console.log(student,parent,teacher,"check",dates,dateSelected)
	useMemo(()=>{
		if(student!=undefined&&typeof(student)!="string")
		{
			const temp=student.map((value)=>value.created_date)
			setDates([...temp])
		}
	},[student])
	useMemo(()=>{
		if(teacher!=undefined&&typeof(teacher)!="string")
		{
			const temp=teacher.map((value)=>value.created_date)
			console.log(temp,"teacher dates")
			const temp1=dates
			temp.map((value)=>value!=undefined&&!temp1.includes(value)&&temp1.push(value))
			setDates([...temp1])
		}
	},[teacher])
	useMemo(()=>{
		if(parent!=undefined&&typeof(parent)!="string")
		{
			const temp=parent.map((value)=>value.created_date)
			const temp1=dates
			console.log(temp,"parent dates")
			temp.map((value)=>value!=undefined&&!temp1.includes(value)&&temp1.push(value))
			
			setDates([...temp1])
		}
	},[parent])
	

	
	const soft_name=eval(student?.soft_name)
	const [data,setData]=useState({
		labels:[],
		student:[],
		parent:[],
		teacher:[]
	})
	
	useMemo(()=>{
		
		student!=undefined&&typeof(student)!="string"&&student.map((value)=>{
			if(value.created_date==dateSelected&&value?.soft_name!=undefined&&value?.soft_skill!=undefined)
			{
				const temp=data
				temp["student"]=eval(value.soft_skill)
				temp["labels"]=eval(value?.soft_name).map((name)=>chunkString(name,25))
				setData({
					...temp
			
				})
				
				
			}
		})
		
	},[dateSelected])
	useMemo(()=>{
		parent!=undefined&&typeof(parent)!="string"&&parent.map((value)=>{
			if(value.created_date==dateSelected&&value?.soft_skill!=undefined)
			{
				const temp=data
				temp["parent"]=eval(value.soft_skill)
				temp["labels"]=eval(value?.soft_name).map((name)=>chunkString(name,25))
				setData({
					...temp
			
				})
			}
		})	},[dateSelected])
		useMemo(()=>{	
		teacher!=undefined&&typeof(teacher)!="string"&&teacher.map((value)=>{
			if(value.created_date==dateSelected&&value?.soft_skill!=undefined)
			{
				const temp=data
				temp["teacher"]=eval(value.soft_skill)
				temp["labels"]=eval(value?.soft_name).map((name)=>chunkString(name,25))
				setData({
					...temp
			
				})
			
			}
		})
	},[dateSelected])

const profiles = apiData?.student_profile?.[0]
const back=user?.role=="Teacher"?"/Students_list":"/AllStudentDetails"

function chunkString(str, maxWidth){
	const sections = [];
	const words = str.split(" ");
	let builder = "";
  
	for (const word of words) {
	  if(word.length > maxWidth) {
		sections.push(builder.trim())
		builder = ""
		sections.push(word.trim())
		continue
	  }
  
	  let temp = `${builder} ${word}`
	  if(temp.length > maxWidth) {
		sections.push(builder.trim())
		builder = word
		continue
	  }
  
	  builder = temp
	}
	sections.push(builder.trim())
  
	return sections;
  }
	return (
		<div className="">
			<Header />
			<div className="student-chart__container">
				<div className="student-chart__box">
					<StudentInfo student={studentDat}/>
					<div className="student-chart__box_profile">
						<Link to={back}>
							<ArrowLeft />
							Back to All Students
						</Link>
						<div className="student-chart__box_main">
							<div className="student-chart__box_header">
								<h1>{profiles?.first_name} {profiles?.last_name}</h1>
								
							</div>
							<div className="student-chart__header_buttons">
                <button  onClick={()=>history("/StudentProfile",{state:{student:studentDat}})}>Student Profile</button>
                    <button onClick={()=>history("/StudentProgressChart",{state:{student:studentDat}})}>Track Progress</button>
                    <button className="active" onClick={()=>history("/StudentChart",{state:{student:studentDat}})}>Map Student Views</button>
                    <button onClick={() =>
											history("/StudentComment", {
												state: { student: studentDat },
											})
										}>
                      {/* className="active" */}
                      Evidence Recorded
                    </button>
					<button onClick={() =>
											history("/NotesForStudent", {
												state: { student: studentDat },
											})
										}>
                      {/* className="active" */}
                      Notes for Student
                    </button>
                    <button onClick={() =>
											history("/ReviewPastIEP", {
												state: { student: profiles },
											})
										}>IEPs</button>
                    <button onClick={() =>
											history("/IEPDocumentRepositry", {
												state: { student: profiles },
											})
										}>Document Repository</button>
                    
                  </div>
							
							<div className="trackbox__container">
								<div className="header">
									<span>Map Student Views</span>
									<div>
									<div className="dates">
					{dates?.map((value) => (
						<span
							style={{ backgroundColor: value == dateSelected && "#FFE4AF" }}
							onClick={() => (setDateSelected(value),
								setData({
									labels:[],
									student:[],
									parent:[],
									teacher:[]
								})
                                )}
						>
							{value}
						</span>
					))}
				</div>
										{/* <div className="learning_outcome">
											<span className="faded">Enter Soft skills</span>
											<DownArrow />
										</div>
										<div className="date">
											<span className="faded">Start Date</span>
											<CalenderIcon />
										</div>
										<div className="date">
											<span className="faded">End Date</span>
											<CalenderIcon />
										</div>
										<div className="search_icon">
											<SearchIcon />
										</div> */}
									</div>
								</div>
								<br/>
								<div ref={componentRef} className="print-chart">
								<div className="trackbox_chart" id="trackbox_chart">
									<Graph data={data}/>
									{/* <Radar
                  id="stackD"
										options={{
											
											scales: {
												r: {
													grid: {
														color: "black",
													},
													angleLines: {
														color: "black",
													},
													beginAtZero: true,
													min: 0,
													max: 5,
													pointLabels: {
														font: {
															size: 14,
															weight: 700,
															color: "black",
														},
													},
													ticks: {
														display: false,
														font: {
															size: 20,
															weight: 700,
															color: "black",
															callback: (value) => `  ${value} `,
														},
													},
												},
											},
											ticks: {
												stepSize: 1, // the number of step
											},
										}}
										data={{
											labels: student != undefined &&eval(student?.soft_name),
											datasets: [
											  {
												label: "Student",
												data: student?.soft_skill != undefined && student.soft_skill.split(",").map((point) => Number(point)),
												backgroundColor: "rgba(255, 255, 255, 0)",
												borderColor: "#F05D46",
												borderWidth: 4,
												pointStyle: "rect",
												radius: 10,
												pointBackgroundColor: "#F05D46",
											  },
											  {
												label: "Teacher",
												data: teacher?.soft_skill != undefined && teacher.soft_skill.split(",").map((point) => Number(point)),
												backgroundColor: "rgba(255, 255, 255, 0)",
												borderColor: "#69B3DD",
												borderWidth: 4,
												pointStyle: "rectRot",
												radius: 10,
												pointBackgroundColor: "#69B3DD",
											  },
											  {
												label: "Carer",
												data: parent?.soft_skill != undefined && parent.soft_skill.split(",").map((point) => Number(point)),
												backgroundColor: "rgba(255, 255, 255, 0)",
												borderColor: "#889159",
												borderWidth: 4,
												pointStyle: "rectRot",
												radius: 10,
												pointBackgroundColor: "#889159",
											  },
											],
										  }}
									/> */}
									
								</div>
								<div className="trackbox_ideas">
									<p>1 = Not yet</p>
									<p>2 = Occassionally</p>
									<p>3 = Sometimes</p>
									<p>4 = Frequently</p>
									<p>5 = Always</p>
								</div>
								</div>
								
                <div style={{
                  display:"flex",
                  justifyContent:"center"
                }
                }> <ReactToPrint
				trigger={() => <button className="download-button">Download</button>}
				content={() => componentRef.current}
			/>{" "}</div>
               
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default StudentChart;
