import "./Step3.css";
import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import KeyOutcome from "./KeyOutcome";
import {
	iep_step3,
	negotiate_goal,
	teacherTagging,
	viewIEPStep3,
	viewIEPStep3ByID,
	iep_step3_draft,
	viewIEPStep3_draft
} from "../../actions/iep_step3";
import { teacherNames } from "../../actions/studentvoicechoice";
import ReactToPrint from "react-to-print";
import { NEGOTIATE_GOAL,IEP_STEP3,IEP_STEP3_DRAFT } from '../../constants/actionTypes';
function Step3({
	student,
	type,
	changeStep,
	comp,
	step3Form,
	setStep3Form,
	key_outcome_print,
	setKey_OutcomePrint,
	iep_id,
}) {
	const user = JSON.parse(localStorage.getItem("profile"));
	const initialForm = {
		transitions: ["", ""],
		transitions_frequency: ["", ""],
		assesment_adjustment: ["", ""],
		assesment_adjustment_frequency: ["", ""],
		examination_adjustments: ["Additional time", ""],
		duration_start_date: ["", ""],
		duration_end_date: ["", ""],
		adjustment_review_schedule: ["", ""],
		student_discussion_schedule: ["", ""],
		career_discussion_schedule: ["", ""],
		lc_email: user?.email,
		email: student?.email,
		school: user?.school_name,
		tag_teachers:[],
		type: type,
		key_outcome_id: [],
		goal: [""],
	};
	const [displayForm, setDisplayForm] = useState({
		transitions: false,
		transitions_frequency: false,
		assesment_adjustment: false,
		assesment_adjustment_frequency: false,
		examination_adjustments: false,
		duration_start_date: false,
		duration_end_date: false,
		adjustment_review_schedule: false,
		student_discussion_schedule: false,
		career_discussion_schedule: false,
		lc_email: user?.email,
		email: student?.email,
		school: user?.school_name,
		type: type,
		key_outcome_id: [],
	});
	const dispatch = useDispatch();
	const apiData = useSelector((state) => state);
	const version = type == "create" ? 0 : 1;
	const [didSave,setDidSave]=useState(false)
	// const [form, setForm] = useState(type == "create" ? initialForm : displayForm);
	const handleSubmit = (e) => {
		e.preventDefault();
		if(didSave)
		{
			alert("You have already saved the form!")
		}
		setDidSave(true)
		console.log("step 3 teachers",{...step3Form,type:viewData==undefined?"create":"revise",tag_teachers:publishData?.teachers||[""]})
		dispatch(iep_step3({...step3Form,type:viewData==undefined?"create":"revise",tag_teachers:publishData?.teachers||[""]}));

		alert("Saved Successfully");
		dispatch(
			viewIEPStep3({ email: student?.email, school: user?.school_name })
		);
		publish(e, publishData);
	};
	const handleDraftSubmit = (e) => {
		e.preventDefault();
		console.log({...step3Form,tag_teachers:publishData?.teachers})
		dispatch(iep_step3_draft({...step3Form,tag_teachers:publishData?.teachers||[""]}));
setTimeout(()=>{dispatch(
			viewIEPStep3_draft({ email: student?.email, school: user?.school_name })
		);},200)
		

		alert("Draft Saved Successfully");
		publish(e, publishData);
	};
	useEffect(() => {
		dispatch(teacherNames({ school: user.school_name }));
		dispatch({ type: IEP_STEP3_DRAFT, payload: "" });
		dispatch({ type: IEP_STEP3, payload: "" });
		dispatch(
			viewIEPStep3_draft({ email: student?.email, school: user?.school_name })
		);
		dispatch({ type: NEGOTIATE_GOAL, payload: {body:[]} });
		dispatch(
			negotiate_goal({ school: user.school_name, email: student?.email })
		);
		if (type != "create" && type != "view") {
			dispatch(
				viewIEPStep3({ email: student?.email, school: user?.school_name })
			);
		}
		
			
		
		if (type == "view") {
			dispatch(viewIEPStep3ByID({ iep_id_3: iep_id }));
		}
	}, []);
	const handleChange = async (e) => {
		const temp = step3Form[e.target.name] || [];
		temp[version] = e.target.value;
		setStep3Form({ ...step3Form, [e.target.name]: temp });
	};
	const handleSelectChange = (value, action) => {
		const temp = step3Form[action.name] || [];
		temp[version] = value["label"];
		setStep3Form({ ...step3Form, [action.name]: temp });
	};
	const frequencyOptions = [
		{ value: "Daily", label: "Daily" },
		{ value: "Every other day", label: "Every other day" },
		{ value: "Weekly ", label: "Weekly " },
		{ value: "Fortnightly", label: "Fortnightly" },
		{ value: "Once a month", label: "Once a month" },
		{ value: "Term", label: "Term" },
		{ value: "Semester", label: "Semester" },
	];
	const levelOfAdjustmentOptions = [
		{ value: "QDTP", label: "QDTP" },
		{ value: "Supplementary", label: "Supplementary" },
		{ value: "Substantial", label: "Substantial" },
		{ value: "Extensive", label: "Extensive" },
	];

	const examination_adjustmentsOptions = [
		{ value: "Additional time", label: "Additional time" },
		{ value: "Rest Breaks", label: "Rest Breaks" },
		{ value: "Using an Interpreter", label: "Using an Interpreter" },
		{ value: "Using a Reader", label: "Using a Reader" },
		{ value: "Using a Scribe", label: "Using a Scribe" },
		{ value: "Using a Computer", label: "Using a Computer" },
		{
			value: "Flexible Time Arrangements",
			label: "Flexible Time Arrangements",
		},
		{ value: "Oral Exams", label: "Oral Exams" },
		{ value: "Take-Home Exams", label: "Take-Home Exams" },
		{ value: "Split an Exam", label: "Split an Exam" },
		{ value: "Other", label: "Other" },
	];
	const [key_outcome, setKey_Outcome] = useState([true, false, false]);
	const [key_outcome_form, setKey_OutcomeForm] = useState([]);
	const [publishData, setPublishData] = useState({
		email: student?.email,
		teachers: [],
	});

	const teacherCheckbox = [];
	for (var i = 0; i < 6; i++) {
		teacherCheckbox.push(false);
	}
	const [checked, setChecked] = useState({
		teacher: teacherCheckbox,
	});
	const [teachers, setTeachers] = useState([]);
	const publish = (e, publishData) => {
		e.preventDefault();
		dispatch(
			teacherTagging({
				email: publishData.email,
				teachers: publishData.teachers.toString(),
				view: publishData.view,
			})
		);
	};
	const goal = apiData?.iep_step3?.negotiate_goal?.negotiate_goal;
	console.log("goal",goal)
	const viewData = apiData.iep_step3?.iep_step3;
	const viewDraftData = apiData.iep_step3?.iep_step3_draft;
	const dateFormat = (date) => {
		return (
			date?.split("-")[2] +
			"-" +
			date?.split("-")[1] +
			"-" +
			date?.split("-")[0]
		);
	};
	
	useMemo(() => {
		if (viewDraftData != undefined && typeof(viewDraftData)!="string") {
			const temp = viewDraftData?.[0];
			setStep3Form({
				transitions: eval(viewDraftData?.[0]?.transitions),
		transitions_frequency: eval(viewDraftData?.[0]?.transitions_frequency),
		assesment_adjustment: eval(viewDraftData?.[0]?.assesment_adjustment),
		assesment_adjustment_frequency: eval(viewDraftData?.[0]?.assesment_adjustment_frequency),
		examination_adjustments: eval(viewDraftData?.[0]?.examination_adjustments),
		duration_start_date: eval(viewDraftData?.[0]?.duration_start_date),
		duration_end_date: eval(viewDraftData?.[0]?.duration_end_date),
		adjustment_review_schedule: eval(viewDraftData?.[0]?.adjustment_review_schedule),
		student_discussion_schedule: eval(viewDraftData?.[0]?.student_discussion_schedule),
		career_discussion_schedule: eval(viewDraftData?.[0]?.career_discussion_schedule),
		lc_email: user?.email,
		email: student?.email,
		school: user?.school_name,
		tag_teachers:eval(viewDraftData?.[0]?.tag_teachers),
		created_date:viewDraftData?.[0]?.created_date,
		iep_id_3:viewDraftData?.[0]?.iep_id_3,
				goal: eval(viewDraftData?.[0]?.goal)||[""],
				key_outcome_id: eval(viewDraftData?.[0]?.key_outcome_id),
				type: "revise",
			});
const ids = eval(viewDraftData?.[0]?.key_outcome_id);
			console.log(ids,"ids");
			if(ids)
{
			setKey_Outcome(ids?.map((value, index) => false));}

			
		}
setPublishData({...publishData,teachers:eval(viewDraftData?.[0]?.tag_teachers)})
	}, [viewDraftData]);
	useMemo(() => {
		if (type != "create") {
			const temp = viewData?.[0];
			setStep3Form({
				transitions: eval(viewData?.[0]?.transitions),
		transitions_frequency: eval(viewData?.[0]?.transitions_frequency),
		assesment_adjustment: eval(viewData?.[0]?.assesment_adjustment),
		assesment_adjustment_frequency: eval(viewData?.[0]?.assesment_adjustment_frequency),
		examination_adjustments: eval(viewData?.[0]?.examination_adjustments),
		duration_start_date: eval(viewData?.[0]?.duration_start_date),
		duration_end_date: eval(viewData?.[0]?.duration_end_date),
		adjustment_review_schedule: eval(viewData?.[0]?.adjustment_review_schedule),
		student_discussion_schedule: eval(viewData?.[0]?.student_discussion_schedule),
		career_discussion_schedule: eval(viewData?.[0]?.career_discussion_schedule),
		lc_email: user?.email,
		email: student?.email,
tag_teachers:eval(viewData?.[0]?.tag_teachers),
		school: user?.school_name,
		created_date:viewData?.[0]?.created_date,
		
				goal: eval(viewData?.[0]?.goal)||[""],
				key_outcome_id: eval(viewData?.[0]?.key_outcome_id),
				type: type,
			});
setPublishData({...publishData,teachers:eval(viewData?.[0]?.tag_teachers)})
			setDisplayForm({
				transitions: temp?.transitions?.[1] ? true : false,
				transitions_frequency: temp?.transitions_frequency?.[1] ? true : false,
				assesment_adjustment: temp?.assesment_adjustment?.[1] ? true : false,
				assesment_adjustment_frequency: temp
					?.assesment_adjustment_frequency?.[1]
					? true
					: false,
				examination_adjustments: temp?.examination_adjustments?.[1]
					? true
					: false,
				duration_start_date: temp?.duration_start_date?.[1] ? true : false,
				duration_end_date: temp?.duration_end_date?.[1] ? true : false,
				adjustment_review_schedule: temp?.adjustment_review_schedule?.[1]
					? true
					: false,
				student_discussion_schedule: temp?.student_discussion_schedule?.[1]
					? true
					: false,
				career_discussion_schedule: temp?.career_discussion_schedule?.[1]
					? true
					: false,
			});
			const ids = eval(viewData?.[0]?.key_outcome_id);
			console.log(ids,"ids");
			
			setKey_Outcome(ids?.map((value, index) => false));
		}
	}, [viewData]);
	useMemo(() => {
		if (goal != undefined && typeof eval(goal) != "string" && type != "view")
			setStep3Form({ ...step3Form, goal: eval(goal) });
		else
		setStep3Form({...step3Form, goal: [""]})
	}, [goal]);
	const handleGoalChange = (e, index) => {
		const temp = step3Form?.goal;
		temp[index] = e.target.value;
		setStep3Form({ ...step3Form, [e.target.name]: temp });
		console.log(temp, index);
	};
	console.log(step3Form,goal,"step 3 12-08-2023")
	return (
		<div className="step3">
			<form onSubmit={handleSubmit}>
				<p className="title">Generate Plan</p>
				<p className="sub-title">Goal</p>
				{/* <p>{goal}</p> */}
				{step3Form?.goal != undefined &&
					typeof step3Form?.goal != "string" &&
					step3Form?.goal.map((value, index) => (
						<label style={{ display: "flex", alignItems: "center" }}>
							Goal {index + 1} :{" "}
							<input
								type={"text"}
								className="text"
								style={{ display: "block" }}
								value={step3Form?.goal?.[index]}
								placeholder="Please enter notes and comments"
								name="goal"
								onChange={(e) => handleGoalChange(e, index)}
							/>
						</label>
					))}
				{/* <input
							type={"text"}
							className="text"
							name="goal"
							value={step3Form?.goal}
							placeholder="Goal"
							onChange={handleChange}
						/> */}
				{key_outcome?.map((value, index) =>
					value ? (
						<KeyOutcome
							key={"key" + index}
							outcome_number={index + 1}
							student={student}
							type={type}
							key_outcome_id={step3Form?.key_outcome_id?.[index]}
							key_outcome_form={key_outcome_form}
							setKey_OutcomeForm={setKey_OutcomeForm}
							setStep3Form={setStep3Form}
							set3Form={step3Form}
							setKey_Outcome={setKey_Outcome}
							key_outcome_array={key_outcome}
							key_outcome_print={key_outcome_print}
							setKey_OutcomePrint={setKey_OutcomePrint}
						/>
					) : (
						<div
							key={"key" + index}
							className="dummmy-keyoutcome"
							onClick={() => {
								const tempFalse = key_outcome;
								tempFalse[index] = !tempFalse[index];
								setKey_Outcome([...tempFalse]);
							}}
						>
							<p className="sub-title">Key Outcome {index + 1}</p>
							<div className="arrow"></div>
						</div>
					)
				)}
				{(!displayForm?.career_discussion_schedule ||
					!displayForm?.student_discussion_schedule ||
					!displayForm?.transitions ||
					!displayForm?.assesment_adjustment ||
					!displayForm?.examination_adjustments ||
					!displayForm?.duration_start_date ||
					!displayForm?.duration_end_date ||
					!displayForm?.adjustment_review_schedule) && (
					<p
						className="add_more"
						onClick={() => {
							const temp = key_outcome;

							temp.push(false);
							setKey_Outcome([...temp]);
						}}
					>
						Add more
					</p>
				)}
				<p className="sub-title">
					Transitions (between Activities / Classes, etc)
				</p>
				{type != "create" && (
					<div className="text-select version1">
						<p style={{ width: "80%" }}>{step3Form?.transitions?.[0]}</p>
						<p>{step3Form?.transitions_frequency?.[0]}</p>
					</div>
				)}
				{displayForm?.transitions ? (
					<div className="text-select version2">
						<p style={{ width: "80%" }}>{step3Form?.transitions?.[1]}</p>
						<p>{step3Form?.transitions_frequency?.[1]}</p>
					</div>
				) : (
					<div className="text-select">
						<textarea
							className="textarea"
							name="transitions"
							value={step3Form?.transitions?.[0]?step3Form?.transitions?.[0]:step3Form?.transitions?.[1]}
							onChange={handleChange}
						/>
						<Select
							name="transitions_frequency"
							options={frequencyOptions}
							onChange={handleSelectChange}
							placeholder={
								<div className="select-placeholder-text">
									
									{(step3Form?.transitions_frequency?.[0])?(step3Form?.transitions_frequency?.[0]||"Frequency"):(step3Form?.transitions_frequency?.[1]||"Frequency")}</div>
							}
						/>
					</div>
				)}

				<p className="sub-title">Assessment Adjustments </p>
				{type != "create" && (
					<div className="text-select version1">
						<p style={{ width: "80%" }}>
							{step3Form?.assesment_adjustment?.[0]}
						</p>
						<p>{step3Form?.assesment_adjustment_frequency?.[0]}</p>
					</div>
				)}

				{displayForm?.assesment_adjustment ? (
					<div className="text-select version2">
						<p style={{ width: "80%" }}>
							{step3Form?.assesment_adjustment?.[1]}
						</p>
						<p>{step3Form?.assesment_adjustment_frequency?.[1]}</p>
					</div>
				) : (
					<div className="text-select">
						<textarea
							className="textarea"
							name="assesment_adjustment"
							value={step3Form?.assesment_adjustment?.[0]?step3Form?.assesment_adjustment?.[0]:step3Form?.assesment_adjustment?.[1]}
							onChange={handleChange}
						/>
						<Select
							name="assesment_adjustment_frequency"
							onChange={handleSelectChange}
							options={frequencyOptions}
							placeholder={
								<div className="select-placeholder-text">
									{(step3Form?.assesment_adjustment_frequency?.[0])?(step3Form?.assesment_adjustment_frequency?.[0]||"Frequency"):(step3Form?.assesment_adjustment_frequency?.[1]||"Frequency")}</div>
							}
						/>
					</div>
				)}
				<p className="sub-title">Examination Adjustments </p>
				{type != "create" && (
					<div className="text-select version1">
						<p>{step3Form?.examination_adjustments?.[0]}</p>
					</div>
				)}
				{displayForm?.examination_adjustments ? (
					<div className="text-select version2">
						<p>{step3Form?.examination_adjustments?.[1]}</p>
					</div>
				) : (
					<div className="text-select">
						<Select
							name="examination_adjustments"
							options={examination_adjustmentsOptions}
							onChange={handleSelectChange}
							placeholder={
								<div className="select-placeholder-text">
									{(step3Form?.examination_adjustments?.[0])?(step3Form?.examination_adjustments?.[0]||"Examination Adjustments"):(step3Form?.examination_adjustments?.[1]||"Examination Adjustments")}
								</div>
							}
						/>

						{(!examination_adjustmentsOptions
							.map((data) => Object.values(data)[0])
							.includes(step3Form?.examination_adjustments?.[version]) ||
							step3Form?.examination_adjustments?.[version] == "Other") && (
							<input
								type={"text"}
								className="text"
								name="examination_adjustments"
								placeholder="Examination Adjustments"
								onChange={handleChange}
							/>
						)}
					</div>
				)}
				<div className="title-select">
					<p className="sub-title" style={{ marginRight: "3rem" }}>
						Duration of IEP{" "}
					</p>
					<div>
						{type != "create" && (
							<p className="version1">
								{" "}
								{step3Form?.duration_start_date?.[0]}&nbsp;&nbsp;&nbsp;
							</p>
						)}
						{displayForm?.duration_start_date ? (
							<p className="version2">
								{step3Form?.duration_start_date?.[1]}&nbsp;&nbsp;&nbsp;
							</p>
						) : (
							<input
								type={"date"}
								className="date datechk"
								name="duration_start_date"
								value={step3Form?.duration_start_date?.[0]?step3Form?.duration_start_date?.[0]:step3Form?.duration_start_date?.[1]}
								onChange={handleChange}
								placeholder="Start Date"
							/>
						)}
					</div>
					<div>
						{type != "create" && (
							<p className="version1">{step3Form?.duration_end_date?.[0]}&nbsp;&nbsp;&nbsp;</p>
						)}
						{displayForm?.duration_end_date ? (
							<p className="version2">{step3Form?.duration_end_date?.[1]}&nbsp;&nbsp;&nbsp;</p>
						) : (
							<input
								type={"date"}
								className="date datechk"
								name="duration_end_date"
								value={step3Form?.duration_end_date?.[0]?step3Form?.duration_end_date?.[0]:step3Form?.duration_end_date?.[1]}
								onChange={handleChange}
								placeholder="End Date"
							/>
						)}
					</div>
				</div>

				<div className="title-select">
					<p className="sub-title">Adjustment Review Schedule </p>
					<div>
						{type != "create" && (
							<p className="version1">
								{" "}
								{step3Form?.adjustment_review_schedule?.[0]}
							</p>
						)}
						{displayForm?.adjustment_review_schedule ? (
							<p className="version2">
								{step3Form?.adjustment_review_schedule?.[1]}
							</p>
						) : (
							<Select
								name="adjustment_review_schedule"
								onChange={handleSelectChange}
								options={frequencyOptions}
								placeholder={
									<div className="select-placeholder-text">
										{(step3Form?.adjustment_review_schedule?.[0])?(step3Form?.adjustment_review_schedule?.[0]||"Every Week"):(step3Form?.adjustment_review_schedule?.[1]||"Every Week")}
										</div>
								}
							/>
						)}
					</div>
				</div>
				<div className="title-select">
					<p className="sub-title">Student Discussion Schedule </p>
					<div>
						{type != "create" && (
							<p className="version1">
								{step3Form?.student_discussion_schedule?.[0]}
							</p>
						)}
						{displayForm?.student_discussion_schedule ? (
							<p className="version2">
								{step3Form?.student_discussion_schedule?.[1]}
							</p>
						) : (
							<Select
								name="student_discussion_schedule"
								onChange={handleSelectChange}
								options={frequencyOptions}
								placeholder={
									<div className="select-placeholder-text">{(step3Form?.student_discussion_schedule?.[0])?(step3Form?.student_discussion_schedule?.[0]||"Every Week"):(step3Form?.student_discussion_schedule?.[1]||"Every Week")}</div>
								}
							/>
						)}
					</div>
				</div>
				<div className="title-select">
					<p className="sub-title" style={{ marginRight: "1.8rem" }}>
						Carer Discussion Schedule{" "}
					</p>
					<div>
						{type != "create" && (
							<p className="version1">
								{step3Form?.career_discussion_schedule?.[0]}
							</p>
						)}
						{displayForm?.career_discussion_schedule ? (
							<p className="version2">
								{step3Form?.career_discussion_schedule?.[1]}
							</p>
						) : (
							<Select
								name="career_discussion_schedule"
								onChange={handleSelectChange}
								options={frequencyOptions}
								placeholder={
									<div className="select-placeholder-text">{(step3Form?.career_discussion_schedule?.[0])?(step3Form?.career_discussion_schedule?.[0]||"Every Week"):(step3Form?.career_discussion_schedule?.[1]||"Every Week")}</div>
								}
							/>
						)}
					</div>
				</div>
				
			<br />
			{(!displayForm?.career_discussion_schedule ||
				!displayForm?.student_discussion_schedule ||
				!displayForm?.transitions ||
				!displayForm?.assesment_adjustment ||
				!displayForm?.examination_adjustments ||
				!displayForm?.duration_start_date ||
				!displayForm?.duration_end_date ||
				!displayForm?.adjustment_review_schedule) && (
				<>
					<label key={"allTeacher"}>
						<input
							type={"checkbox"}
							className="checkbox"
							name="tag_all_teachers"
							onChange={(e) => {
								const temp = checked.teacher.map(function (bool) {
									return !bool;
								});

								setChecked({ ...checked, teacher: temp });
								setPublishData({
									...publishData,
									teachers: apiData.studentvoicechoice.teacherNames?.map(
										(data, index) => (temp[index] ? data.email : "")
									),
								});
							}}
						/>
						Tag all teachers
					</label>	</>
			)}
			{console.log(student?.teacher,"show")}
					<div className="teachers">
						{apiData?.studentvoicechoice?.teacherNames?.map((value, index) => (

							<label key={value.first_name}>
								<input
									type={"checkbox"}
									className="checkbox"
									name="teacher"
									value={value.email}
									checked={checked.teacher[index]||eval(step3Form?.tag_teachers)?.includes(value.email)}
									onChange={(e) => {
										const temp = checked.teacher;
										temp[index] = !checked.teacher[index];
										setChecked({ ...checked, teacher: temp });
										if (
											!teachers.includes(e.target.value) &&
											checked.teacher[index]
										) {
											const temp = publishData.teachers;
											temp.push(e.target.value);
											setPublishData({ ...publishData, teachers: temp });
										} else {
											const temp = publishData.teachers;
											temp.splice(temp.indexOf(e.target.value), 1);

											setPublishData({ ...publishData, teachers: temp });
										}
									}}
								/>
								{value.first_name + " " + value.last_name}
							</label>
						))}

					</div>
			
{(!displayForm?.career_discussion_schedule ||
					!displayForm?.student_discussion_schedule ||
					!displayForm?.transitions ||
					!displayForm?.assesment_adjustment ||
					!displayForm?.examination_adjustments ||
					!displayForm?.duration_start_date ||
					!displayForm?.duration_end_date ||
					!displayForm?.adjustment_review_schedule) && (
					<div className="buttons">
						<button className="back" onClick={() => changeStep("step2")}>
							Back
						</button>
<button className="save" onClick={handleDraftSubmit}>
							Save Draft
						</button>
						<button className="save" type="submit">
							Save
						</button>
						
					</div>
				)}
			</form>
			<ReactToPrint
				trigger={() => <button className="publish">Publish</button>}
				content={() => comp.current}
			/>
		</div>
	);
}

export default Step3;
