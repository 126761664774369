import "./StudentComment.css";
import React, { useState, useEffect,useRef, useMemo } from "react";
import Header from "../Header"
import Footer from "../Footer"
import { Link } from "react-router-dom";
import { ArrowLeft, CalenderIcon, DownArrow, SearchIcon } from "../../assets/Icons";
import StudentInfo from "../StudentInfo";
import { useFilter } from "@promise_learning/usefilter";

import { teachercomments,student_profile } from "../../actions/studentprofile";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Pagination from "./../Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
  


const StudentComment = () => {
  function dateToYMD(date) {
		var d = date.getDate();
		var m = date.getMonth() + 1; //Month from 0 to 11
		var y = date.getFullYear();
		return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
	}
  const dateFormat = (date) => {
		return (
			date?.split("-")[2] +
			"-" +
			date?.split("-")[1] +
			"-" +
			date?.split("-")[0]
		);
	};
  const componentRef = useRef();
  const history = useNavigate();  
  const dispatch = useDispatch();
  const location = useLocation();  
  const [startDate,setStartDate]=useState();
const [endDate,setEndDate]=useState();
  const student=location.state?.student
const apiData = useSelector((state) => state.studentprofile);
const user = JSON.parse(localStorage.getItem("profile"))
useEffect(() => {
		dispatch(
			teachercomments({
				student_email_id:student.email,
				school: user.school_name,
				active: "yes",
			}))
			
	}, []);
	useEffect(() => {
		dispatch(
		  student_profile({
			school: user?.school_name,
			email: student?.email,
		  })
		);
	  }, []);
	const teachercommentsData = apiData?.teachercomments
  var datefifteredcoments=teachercommentsData
  useMemo(()=>{
    datefifteredcoments = (endDate?datefifteredcoments:teachercommentsData)?.filter((item) => {
     let date = dateFormat(item?.creation_date)
     console.log(startDate<date,startDate,date,item?.creation_date)
      if (startDate&&!(startDate<=date)) {
        
      return false
      }
    
    return true
    })
    
     console.log(datefifteredcoments,"startDate")
 },[startDate])
 useMemo(()=>{
  
  datefifteredcoments = (startDate?datefifteredcoments:teachercommentsData)?.filter((item) => {
    let date = dateFormat(item?.creation_date)
     if (endDate&&!(date<=endDate)) {
     return false
     }
   
   return true
   })
   console.log(datefifteredcoments,"endDate")
},[endDate])
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState({
		query: "",
		fields: ["first_name","last_name","lesson_modification"],
	});
  const profiles = apiData?.student_profile?.[0]
  let PageSize = 10;
	const { loading, data: result } = useFilter({
		data: datefifteredcoments,
		search: searchData,
	});
const currentTableData = useMemo(() => {
		const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
		return result?.slice(firstPageIndex, lastPageIndex);
	}, [currentPage, result]);

	
  const back=user?.role=="Teacher"?"/Students_list":"/AllStudentDetails"
  const publishDate=new Date()

  return (
    <div className="student-comment" >
      <Header />
      <div className="student-comment__container">
        <div className="student-comment__box">
          <StudentInfo student={student}/>
          <div className="student-comment__box_profile">
            <Link to={back}>
              <ArrowLeft />
              Back to All Students
            </Link>
            <div className="student-comment__box_main">
              <div className="student-comment__box_header">
                <h1>{profiles?.first_name} {profiles?.last_name}</h1>
                
              </div>
              <div className="student-chart__header_buttons">
                <button  onClick={()=>history("/StudentProfile",{state:{student:student}})}>Student Profile</button>
                    <button onClick={()=>history("/StudentProgressChart",{state:{student:student}})}>Track Progress</button>
                    <button  onClick={()=>history("/StudentChart",{state:{student:student}})}>Map Student Views</button>
                    <button className="active" onClick={() =>
											history("/StudentComment", {
												state: { student: student },
											})
										}>
                      {/* className="active" */}
                      Evidence Recorded
                    </button>
                    <button onClick={() =>
											history("/NotesForStudent", {
												state: { student: student },
											})
										}>
                      {/* className="active" */}
                      Notes From Teacher

                    </button>
                    <button onClick={() =>
											history("/ReviewPastIEP", {
												state: { student: profiles },
											})
										}>IEPs</button>
                    <button onClick={() =>
											history("/IEPDocumentRepositry", {
												state: { student: profiles },
											})
										}>Document Repository</button>
                    
                  </div>
              <div className="trackbox__container" >
                <div className="header">
                  <div style={{display:'block'}}>
                  <span style={{fontSize: "25px",
    fontWeight: "bold",
    color: "#092433"}}>Evidence Recorded</span>
                  <div style={{display:'flex'}}>
                  <div className="date_search_div"><p>Start Date :</p><input type={"date"} className="search-date" onChange={e => setStartDate(e.target.value)}/></div>
										<div className="date_search_div"><p>End Date :</p><input type={"date"} className="search-date" onChange={e => setEndDate(e.target.value)}/></div>
                    </div>     </div>  <div>

                  <input
							className="search-input"
							onChange={(e) =>
								setSearchData({ ...searchData, query: e.target.value })
							}
							placeholder="Search"
							value={searchData.query}
						/>
                    {/* <div className="learning_outcome">
                      <span className="faded">
                      
                      Search by Keyword
                      </span>
                      <DownArrow />
                    </div>
                    <div className="date">
                      <span className="faded">Search by date</span>
                      <CalenderIcon />
                    </div>
                    <div className="date">
                      <span className="faded">Sort by: Latest</span>
                      <DownArrow />
                    </div>
                    <div className="search_icon">
                      <SearchIcon />
                    </div> */}
                  </div>
                </div>
                <div className="comment_outer_container" >
                <div className="comment_inner_container" >
                  {typeof(currentTableData)!="string"&&currentTableData!=undefined&&
                  
                  currentTableData?.map((comments)=>
<div>
                 
                  <div className="comment_container">
                    <p>{comments.first_name+" "+comments.last_name} : 
                    <span>{comments.creation_date}</span>
                    </p>
                    <div className="comment">
                    <p className="comment_title">Lesson Modification Notes</p>
                      <p>{comments.lesson_modification} </p>
                    </div>
                  </div>
                  <hr className="line"/>
                  </div>
                  )
                  }
                  
                 
                </div>
                </div>
                <ReactToPrint
				trigger={() => <button className="publish">Publish</button>}
				content={() => componentRef.current}
			/>
              </div>
           
            </div>
           
          </div>
          <Pagination
						className="pagination-bar"
						currentPage={currentPage}
						totalCount={teachercommentsData?.length > 0 ? teachercommentsData?.length : 10}
						pageSize={PageSize}
						onPageChange={(page) => setCurrentPage(page)}
					/>
        </div>
       
      </div>
      
      <Footer />
      <div className="print" ref={componentRef}>
      <div style={{display:"flex",justifyContent:"flex-end"}}>
		<p><span>Date : </span>{dateToYMD(publishDate)}</p>
	</div>
			
			<h3 className="title"> {profiles?.first_name} {profiles?.last_name} </h3>
                  {typeof(currentTableData)!="string"&&currentTableData!=undefined&&
                  
                  currentTableData?.map((comments)=>
<div>
                 
                  <div className="comment_container">
                    <p>{comments.first_name+" "+comments.last_name} : 
                    <span>{comments.creation_date}</span>
                    </p>
                    <div className="comment">
                    
                      <p>{comments.lesson_modification} </p>
                    </div>
                  </div>
                  <hr />
                  </div>
                  )
                  }
                  
                 
                </div>
    </div>
  );
};

export default  StudentComment;
