import "./Step1.css";
import React, { useEffect, useMemo,useState } from "react";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
	iep_step_1_impInfo,
	iepid,
	iep_step_1_studentDetails,
	iep_step_1_personalInfo,
} from "../../actions/iep_step1";
import {
	roledisplay
} from "../../actions/school_systemadmin";
import assessment_request_format from "./../ExcelToDB/templates/assessment_request_format.xlsx"
import exportFromJSON from "export-from-json";
function Step1({student,type,changeStep,setStep1,setPopup,setUploadData}) {
	const dispatch = useDispatch();
	const apiData = useSelector((state) => state.iep_step1);
	const teacher = useSelector((state) => state.school_systemadmin?.teacher);
	// useEffect(() => {
		
	// 	dispatch(
	// 		iep_step_1_studentDetails({
	// 			email: "Ashley.Williams@test.com",
	// 			school_name: "Devian International",
	// 		})
	// 	)
	// 	dispatch(
	// 		iep_step_1_impInfo({
	// 			email: "Ashley.Williams@test.com",
	// 			school_name: "Devian International",
	// 		})
	// 	)
	// 	dispatch(
	// 		iep_step_1_personalInfo({
	// 			email: "Ashley.Williams@test.com",
	// 			school_name: "Devian International",
	// 		})
	// 	);
		
	// },[dispatch]);
	const user= JSON.parse(localStorage.getItem('profile'))
	useEffect(()=>{
		dispatch(
			iep_step_1_studentDetails({
			email: student?.email,
			school_name: user.school_name,
		})
	)
	dispatch(
		iep_step_1_impInfo({
			email: student?.email,
			school_name: user.school_name,
		})
	)
	dispatch(
		iep_step_1_personalInfo({
			email: student?.email,
			school_name: user.school_name,
		})
	);
	dispatch(
		roledisplay({
			role:"Teacher",
			schools: user?.school_name,
			active: "yes",
		})
	);
	// dispatch(
	// 	iepid({
	// 		email: student.email,
	// 		school_name: user.school_name,
	// 	})
	// );
	},[])
	
	const iepId = apiData?.iepid;
	const studentdetails = apiData?.iep_step_1_studentdetails;
	const impinfo = apiData?.iep_step_1_impinfo;
	const personalinfo = apiData?.iep_step_1_personalinfo;
	useMemo(()=>{
		setStep1({
			student:student,
			studentdetails:studentdetails,
			impinfo:impinfo,
			personalinfo:personalinfo,
		})
	},[studentdetails,impinfo,personalinfo])

	console.log("student",student)
	const ExportToExcel = () => {
		
		var data=[Object.assign({}, studentdetails, impinfo)];
		var exportType='xls'
		var fileName='assessment_request'
		exportFromJSON({ data, fileName, exportType })
	  }
	  const date=new Date()
	return (
		<div className="step1">
			
			<p className="title"> Student Details</p>
			<div className="student-details grid-container">
				<div className="detail">
					<p className="sub-title">Name</p>
					<p>{student?.first_name + " " + student?.last_name}</p>
				</div>
				<div className="detail">
					<p className="sub-title">DOB</p>
					<p>{student?.date_of_birth}</p>
				</div>
				<div className="detail">
					<p className="sub-title">Student ID</p>
					<p>{student?.reg_id}</p>
				</div>
				<div className="detail">
					<p className="sub-title">Grade</p>
					<p>{student?.class_year}</p>
				</div>
				<div className="detail">
					<p className="sub-title">Teachers</p>
					<p>{teacher!=undefined&&typeof(teacher)!='string'&&teacher?.map((value)=><strong>{value.first_name+" "+value.last_name+","}</strong>)}</p>
				</div>
				<div className="detail">
					<p className="sub-title">Date</p>
					<p>{date.getDate()+"-"+date.getMonth()+"-"+date.getFullYear()}</p>
				</div>
			</div>
			<div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
			<p className="title">Important Information</p>
			{type!="view"&&<div className="action_buttons">
                        {/* <a className="button download" href={assessment_request_format}  download="assessment_request.xlsx">Download Template</a> */}
						<button className="button download" onClick={ExportToExcel}>Download Existing Data</button>
                        <button
						className="button upload"
						onClick={() => {
							setPopup(true);
							setUploadData({
								table_name: "assessment_request",
							});
						}}
					>
						Upload File
					</button></div>}
			</div>
			<div className="important-information grid-container">
				<div className="impo-info">
					<p className="sub-title">Student NDIS Status</p>
					<p>{impinfo?.ndis_status||"NA"}</p>
				</div>
				<div className="impo-info">
					<p className="sub-title">Student NCCD Status</p>
					<p>{impinfo?.nccd_status||"NA"}</p>
				</div>
				<div className="impo-info">
					<p className="sub-title">Disability Category</p>
						<p>{impinfo?.disability_category||"NA"}</p>
				
				</div>
				<div className="impo-info">
					<p className="sub-title">Diagnostic</p>
					
						
						{impinfo?.diagnostics?.split(",").map((value)=>(<p>{value}</p>))||<p>NA</p>}
					
				</div>
				<div className="impo-info">
					<p className="sub-title">School Based Professional Assessments</p>
				
						<p>NA</p>
				
				</div>
				<div className="impo-info">
					<p className="sub-title">Outside Agencies Involved </p>
				
						<p>{impinfo?.outside_agencies||"NA"}</p>
				
				</div>
				<div className="impo-info">
					<p className="sub-title">Current Referrals </p>
			
						<p>{impinfo?.current_referrals||"NA"}</p>
				
				</div>
				<div className="impo-info">
					<p className="sub-title">Evidence of Functional Impact</p>
				
						<p>NA</p>
			
				</div>
			</div>
			<p className="title">Personal Information</p>
			<div className="personal-information">
			<div className="pers-info">
					<p className="sub-title">Preferred Learning Style </p>
					
						<p>{personalinfo?.review_learning_style}</p>
				
				</div>
				<div className="pers-info">
					<p className="sub-title">Student Strengths & Interests</p>
				
						<p>{personalinfo?.review_strength}</p>
						<p>{personalinfo?.review_interest}</p>
				
				</div>
				
				
				<div className="pers-info">
					<p className="sub-title">Barriers to learning </p>
				
						<p>{personalinfo?.barriers_learning}</p>
				
				</div>
				<div className="pers-info">
					<p className="sub-title">
						Student Enabling Beliefs{" "}
					</p>
				
						
						<p>{personalinfo?.review_enabling_belief}</p>
				
				</div>
				<div className="pers-info">
					<p className="sub-title">How goals can be achieved </p>
					<p>{eval(personalinfo?.how_goals_can_be_achieved)?.map((value) => (
							<p>{value}</p>
						))}</p>
					
				
				</div>
				<div className="pers-info">
					<p className="sub-title">Teacher/s can do something additionally or differently </p>
					<p>{eval(personalinfo?.teachers_can_do_additionally)?.map((value) => (
							<p>{value}</p>
						))}</p>
					
				
				</div>
			</div>
			
		<div className="buttons">
				{/* <button className="back">Back</button> */}
				<button className="save-continue" onClick={()=>changeStep("step2")}>Continue</button>
			</div>
		</div>
	);
}

export default Step1;
