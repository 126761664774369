import "./css/Footer.css"
import { FacebookIcon, TwitterIcon, LinkedinIcon } from "../assets/Icons";

const Footer = () => {
    return (
        <footer className="footer__main">
            {/* <FacebookIcon />
            <TwitterIcon />
            <LinkedinIcon /> */}
        </footer>
    )
}

export default Footer
