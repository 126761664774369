import "./ConversationParentTeacher.css";
import React, { useState, useEffect } from "react";
import Footer from "../Footer";
import Header from "../Header";
import ParentView from "./ParentView";

import InformationRecords from "./InformationRecords";
import StudentInfo from "../StudentInfo";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {teacherNames,formSubmit,teacherTagging,findEmailByParent} from "../../actions/studentvoicechoice"

import { mapstudentviews } from "../../actions/studentprofile";
import { useMemo, useRef } from "react";
import ReactToPrint from "react-to-print";

function ConversationParent() {
	
	
	const user= JSON.parse(localStorage.getItem('profile'))
	const apiData = useSelector((state) => state?.studentvoicechoice);
	console.log("apicall",apiData,user)

	const soft_skill_options=useSelector((state)=>state.studentprofile?.student?.slice(-1)[0]?.soft_name)
const dispatch = useDispatch();
	useEffect(() => {
	dispatch(teacherNames({ school: user.school_name }))
	// setChecked({...checked,teacher:apiData.teacherNames?.map((data)=>false)});
	},[])
	const handleSubmit = (e,form) => {
		e.preventDefault();
		console.log(JSON.stringify(form));
		dispatch(formSubmit(form))
		alert("Saved Successfully")
		
	};
	const publish = (e,publishData) => {
		e.preventDefault();
		dispatch(teacherTagging({email:publishData.email,teachers:publishData.teachers.toString(),view:publishData.view}))
		console.log(JSON.stringify(publishData));
		alert("Published Successfully")
	};
	useEffect(()=>{
		console.log("user_parent",user?.email)
		dispatch(findEmailByParent({parent:user?.email}))
	},[])
	
	const [student,setStudent]=useState(apiData.studentparent)
	useMemo(()=>{
		if (apiData.studentparent!=undefined&&typeof(apiData.studentparent)!="string"&&apiData.studentparent!="no records found")
		{
			console.log("stupid")
			setStudent({...apiData.studentparent})}
	},[apiData.studentparent])
	useMemo(() => {
		dispatch(
			mapstudentviews({
				email: student?.email,
				school_name: user.school_name,
				active: "yes",
				view: "student",
			})
		);
	}, [student]);
	const [activeView, setActiveView] = useState({
		
		parentView: {
			isActive: true,
			style: { backgroundColor: "#092433", color: "white" },
		},
		
        
        informationRecords: {
			isActive: false,
			style: { backgroundColor: "#fff", color: "#69B3DD",border:"#69B3DD solid .1rem" },
		},
	});
	
	const changeView = (view) => {
		const temp = {
			
			parentView: {
                isActive: false,
                style: { backgroundColor: "#fff", color: "#092433" },
            },
           
            
            informationRecords: {
                isActive: false,
                style: { backgroundColor: "#fff", color: "#69B3DD",border:"#69B3DD solid .1rem" },
            },
		};
        if(view=="informationRecords")
        {
            temp[view] = {
                isActive: true,
                style: { backgroundColor: "#69B3DD", color: "white",border:"#69B3DD solid .1rem" },
            }; 
        }
        else
		{temp[view] = {
			isActive: true,
			style: { backgroundColor: "#092433", color: "white" },
		};}
		setActiveView(temp);
	};
	const [activeTab, setActiveTab] = useState({
		tab1: {
			isActive: "block",
			style: { backgroundColor: " #FFE4AF", color: "#092433" },
		},
		tab2: {
			isActive: "none",
			style: { backgroundColor: "#fff", color: "#092433" },
		}
	});
	const changeTab = (view) => {
		const temp = {
			tab1: {
				isActive: "none",
				style: { backgroundColor: "#fff", color: "#092433" },
			},
			tab2: {
                isActive: "none",
                style: { backgroundColor: "#fff", color: "#092433" },
            }}
			temp[view] = {
			isActive: "block",
			style: { backgroundColor: " #FFE4AF", color: "#092433" },
		};
		setActiveTab(temp);
	};
	const componentRef = useRef();
	console.log(student,apiData,"parent Convo")
	return (
		<div className="studentvoicechoice" ref={componentRef}>
			<Header />
			<div className="container">
		

				
				<div className="studentvoicechoice-container">
					<p>Conversation with Parent and Teacher</p>
					<div className="views">
						
                        <p
							style={activeView.parentView.style}
							onClick={() => changeView("parentView")}
						>
							Carer / Parent View
						</p>
                     
                       
                        <p
							style={activeView.informationRecords.style}
							onClick={() => changeView("informationRecords")}
						>
							Conversation Records
						</p>
						
					</div>
					<hr className="line" />
					{!activeView.informationRecords.isActive &&
					<div className="tabs">
						<p
							style={activeTab.tab1.style}
							onClick={() => changeTab("tab1")}
						>
							Conversation to Develop IEP 
						</p>
                        <p
							style={activeTab.tab2.style}
							onClick={() => changeTab("tab2")}
						>
							Conversation to Review IEP
						</p>
                        
						
					</div>}
					<div className="view-container">
						{activeView.parentView.isActive && <ParentView tab={activeTab} student={student} apiData={apiData} handleSubmit={handleSubmit} publish={publish} soft_skill_options={soft_skill_options}/>}
						
                        {activeView.informationRecords.isActive && <InformationRecords studentData={student} />}
					</div>
				</div>
				{activeView.informationRecords.isActive &&
				<ReactToPrint
				trigger={() => <button className="publish">Download</button>}
				content={() => componentRef.current}
			/>}
			</div>
			<Footer />
		</div>
	);

	}
export default ConversationParent;
