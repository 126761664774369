import React, { useState } from "react";

export const Goal = ({ form, setForm, handleChange, handleGoalChange }) => {
	return (
		<div className="dynamicgoal">
			{form?.negotiate_goal.map((value, index) => (<div style={{border:"#D3D3D3 solid thin",borderRadius:"1rem",padding:"1rem",marginBottom:"1rem"}}>
            <div className="question">
				<p
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					Negotiate goal{" "}
					
				</p>

				<div className="goal">
			
						<input
							type={"text"}
							className="text"
							value={form?.negotiate_goal?.[index]}
							placeholder="Please enter notes and comments"
							name="negotiate_goal"
							onChange={(e) => handleGoalChange(e, index)}
						/>
				
				</div>
			</div>
			<br />
			<div className="question">
				<input type={"checkbox"} className="checkbox" />
				<p>What can we do to achieve these goals?</p>
				<input
					type={"text"}
					className="text"
					placeholder="Please enter notes and comments"
					name="how_goals_can_be_achieved"
					value={form?.how_goals_can_be_achieved?.[index]}
					onChange={(e) => handleGoalChange(e, index)}
				/>
			</div>
			<br />
			<div className="question">
				<input type={"checkbox"} className="checkbox" />
				<p>Discuss any concerns.</p>
				<input
					type={"text"}
					className="text"
					placeholder="Please enter notes and comments"
					name="any_concerns"
					value={form?.any_concerns?.[index]}
					onChange={(e) => handleGoalChange(e, index)}
				/>
			</div>
			<br />

			<div className="question">
				<input type={"checkbox"} className="checkbox" />
				<p>
					Explore whether teacher/s can do something additionally or
					differently.
				</p>
				<input
					type={"text"}
					className="text"
					placeholder="Please enter notes and comments"
					name="teachers_can_do_additionally"
					value={form?.teachers_can_do_additionally?.[index]}
					onChange={(e) => handleGoalChange(e, index)}
				/>
			</div>
			<br />
            </div>))}
        </div>
	);
};
