import "./GeneralChat.css";
import React, { useState, useEffect, useMemo } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { teacherassessmentrequest } from "../../actions/teacherdashboard";
import { studentdetails } from "../../actions/assessmentrequest";
import {
	ViewGeneralChatByCoordinator,
	ViewGeneralChatByTeacher,
	UpdateGeneralChat,
	CreateGeneralChat,
} from "../../actions/generalchat";
import { Tabs } from "./Tabs";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import handshake from "./../images/handshake.png";
import calendar from "./../images/calendar.png";
import warning from "./../images/warning.png";
import flag from "./../images/waving-flag-.png"
import improve from "./../images/improve.png";
import { useLocation } from "react-router-dom";
const GeneralChat = () => {
	const user = JSON.parse(localStorage.getItem("profile"));
	const role = user?.role;
	
	const dispatch = useDispatch();
	const history = useNavigate();
	const location = useLocation();
	const lcStudent = useSelector((state) => state.auth?.studentdetails);
	const teacherStudent = useSelector(
		(state) => state.teacher_dashboard?.teacherassessmentrequest
	);
	const messagelist = useSelector((state) => state.general_chat?.chatdata);
	console.log(messagelist);
	const [form, setForm] = useState({
		sender: user?.email,
		student: location?.state?.student?.email||"",
		chat: "",
		receiver: [],
	});
	const [student, setStudent] = useState();
	const [studentNotice, setStudentNotice] = useState([]);
	const [isReadMore,setIsReadMore]=useState([])
	
	useMemo(() => {
		if (
			lcStudent != undefined &&
			typeof lcStudent != "string" &&
			role == "Coordinator"
		) {
			setStudent(lcStudent);
		}
	}, [lcStudent]);
	useMemo(() => {
		if (
			teacherStudent != undefined &&
			typeof teacherStudent != "string" &&
			role == "Teacher"
		) {
			setStudent(teacherStudent);
		}
	}, [teacherStudent]);
	
	const handleChange = (e) => {
		setForm({ ...form, [e.target.name]: e.target.value });
	};
	useEffect(() => {
		if (role == "Teacher") {
			dispatch(
				teacherassessmentrequest({
					email: user?.email,
					school: user?.school_name,
				})
			);
		} else if (role == "Coordinator") {
			dispatch(
				studentdetails({
					school: user?.school_name,
					learning_coordinator: user?.email,
				})
			);
		}
	}, []);
    const viewData=()=>{
        if (role == "Teacher") {
			dispatch(
				ViewGeneralChatByTeacher({
					teacher: user?.email,
				})
			);
		} else if (role == "Coordinator") {
			dispatch(
				ViewGeneralChatByCoordinator({ learning_coordinator: user?.email })
			);
		}
    }
	useEffect(() => {
		viewData()
	}, []);
    
	const handleSubmint = (e) => {
		e.preventDefault();
		console.log(form);
		dispatch(CreateGeneralChat(form));
        setTimeout(()=>{viewData()},300)
		setForm({...form,chat:""})
	};
	const handleSeen = (e, id) => {
		e.preventDefault();
		const temp = form?.receiver;
		if (!temp.includes(user?.email)) {
			temp.push(user?.email);
		}
		console.log({ chat_id: id, receiver: temp, status: "Seen" });
		dispatch(
			UpdateGeneralChat({ chat_id: id, receiver: temp, status: "Seen" })
		);
        setTimeout(()=>{viewData()},300)
	};
	useMemo(()=>{
		const count=messagelist != undefined &&
							typeof messagelist != "string" &&
							messagelist.map(
								(data) =>
                             {  
									if(data.student == form?.student)
								return data.chat_record.length
								})
		const temp=Array.apply(null, { length: count }).map((value)=>(true))
		setIsReadMore([...temp])
	},[form?.student])
	useMemo(()=>{
		messagelist != undefined &&
							typeof messagelist != "string" &&
							messagelist.map(
								(data) =>
                             ( 
		data.chat_record.map((message,index) => {
			if(message.status== "Not Seen")
			{const temp=studentNotice
			temp.push(data.student)
			setStudentNotice([...temp])
			}
		
		
})))

	},[messagelist])
	const apiData = useSelector((state) => state.auth);
 const messageCount=useSelector((state)=>state.general_chat?.chatdata)
  
	return (
		<div className="generalchat">
			<Header />
			<div className="main-container">
			{role!="Teacher"&&<div className="dashboard-right">
          
          <div className="card" style={{backgroundColor:"#FCB730"}} onClick={()=>history("/AssessmentRequests")}>
          <img src={flag} />
            <div className="text">
              <p className="number"
               
              >
               {apiData.studentassessmentcount>0?Number(apiData.studentassessmentcount):0}
              </p>
              <p
              className="title"
                
              >
                Assessment Requests
              </p>
            </div>
          </div>
       <div className="card" style={{ backgroundColor: "#889159" }} onClick={()=>history("/CurrentTracking")}>
            <img src={improve} />
            <div className="text">
              <p className="number"
               
              >
                {apiData.allreviewcount>0?apiData.allreviewcount:0}
              </p>
              <p
                className="title"
              >
                Current Tracking
              </p>
            </div>
          </div>
      <div className="card" style={{ backgroundColor: "#D3AB88" }} onClick={()=>history("/UpcomingReview")}>
            <img src={calendar} />
            <div className="text">
              <p
               className="number"
              >
                {apiData.upcomingreviewcount>0?apiData.upcomingreviewcount:0}
              </p>
              <p
                className="title"
              >
                Upcoming reviews
              </p>
            </div>
          </div>
         <div className="card" style={{ backgroundColor: "#F05D46" }} onClick={()=>history("/OverdueReview")}>
            <img src={warning} />
            <div className="text">
              <p
                className="number"
              >
                {apiData.overduereviewcount>0?apiData.overduereviewcount:0}
              </p>
              <p
                className="title"
              >
                Overdue IEP reviews
              </p>
            </div>
          </div>
        </div>}
		
			<div className="container">
			
				<p>{role=="Teacher"?"Learning Coordinator ":"Teacher "}Discussions </p>
				<Tabs student={student} form={form} setForm={setForm} studentNotice={studentNotice} />
				<div className="chat-container">
					<div className="messages-container">
						{messagelist != undefined &&
							typeof messagelist != "string" &&
							messagelist.map(
								(data) =>
                             (   
								
									data.student == form?.student &&
									[...data.chat_record].reverse().map((message,index) => (
                                        
										<div
											className="message-button"
											style={{
												alignSelf: message?.sender == user?.email && "flex-end",
											}}
										>
											<div className="message">
												<p>
													{message?.first_name + " " + message?.last_name} :{" "}
													<span>{message?.creation_date}</span>
												</p>
												<div className="message-content">
													<p>{message?.chat?.length>150?isReadMore?.[index] ? message?.chat.slice(0, 150): message?.chat: message?.chat }
											{message?.chat.length>150&&<span style={{color:'#135eff',display:"block"}}onClick={()=>{
												const temp=isReadMore
												temp[index]=!temp[index]
												setIsReadMore([...temp])
}}>
          {isReadMore?.[index] ? '...read more' : ' ...show less'}
        </span>}</p>
												</div>
											</div>
											<button onClick={(e) => handleSeen(e, message?.chat_id)} disabled={message?.sender == user?.email}>
												{(message?.sender == user?.email)?(message?.status):(message?.status == "Not Seen"
													? "Mark as Seen"
													: message?.status)}
											</button>
										</div>
									))
							))}
						{form?.student == "" && (
							<p className="blank-display">Select a student to chat about.</p>
						)}
					</div>
					<div style={{ clear: "both" }}></div>
					<div className="write-message">
						<textarea name="chat" placeholder="New message" value={form?.chat} onChange={handleChange} />
						<button onClick={handleSubmint}>Post</button>
					</div>
				</div>
			</div>
			</div>
			<Footer />
		</div>
	);
};

export default GeneralChat;






