const apifive = "http://localhost:5005"
const apisix = "http://localhost:5006"
const apiseven = "http://localhost:5007"
const apiten = "http://localhost:5010"
const apitwelve = "http://localhost:5012"

export {
    apifive, 
    apisix, 
    apiseven, 
    apiten,
    apitwelve
}
