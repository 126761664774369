import "./StudentProgressChart.css";
import React, { useState, useEffect, useMemo } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import {
	ArrowLeft,
	CalenderIcon,
	DownArrow,
	SearchIcon,
} from "../../assets/Icons";
import StudentInfo from "../StudentInfo";

import { Graph } from "./Graph";
import { student_profile,Student_targetedOutcome } from "../../actions/studentprofile";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Pagination from "./../Pagination/Pagination";


const StudentProgressChart = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useNavigate();
	const studentDat = location.state?.student;

	const user = JSON.parse(localStorage.getItem("profile"))
	const apiData = useSelector((state) => state.studentprofile);
	useEffect(() => {
		dispatch(
			Student_targetedOutcome({
			  school: user?.school_name,
			  student: studentDat?.email,
			})
		  );
	}, []);
	useEffect(() => {
		dispatch(
		  student_profile({
			school: user?.school_name,
			email: studentDat?.email,
		  })
		);
	  }, []);
	
	  const data= useSelector((state) => state.studentprofile?.progressdata);
	

const profiles = apiData?.student_profile?.[0]
const back=user?.role=="Teacher"?"/Students_list":"/AllStudentDetails"
const [search, setSearch] = useState("")
const color=["#61C4FC"," #f4cf4f","rgb(128, 162, 120)","#D3AB88","#F05D46"]
const [currentPage, setCurrentPage] = useState(1);
    let PageSize = 8;
	const currentTableData = useMemo(() => {
		const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
		return data && data.slice(firstPageIndex, lastPageIndex)
	}, [currentPage, data]);
  let results

  if (search) {
    results = data.filter(item => {
      let found = false
      Object.entries(item).map(([key, value]) => {
        if (String(value).toLowerCase().includes(search.toLowerCase())) {
          found = true
        }
      })
      return found
    })
    results.length = PageSize
  } else {
    results = currentTableData
  }
const [startDate,setStartDate]=useState();
const [endDate,setEndDate]=useState();
	return (
		<div className="">
			<Header />
			<div className="student-progress-chart__container">
				<div className="student-progress-chart__box">
					<StudentInfo student={studentDat}/>
					<div className="student-progress-chart__box_profile">
						<Link to={back}>
							<ArrowLeft />
							Back to All Students
						</Link>
						<div className="student-progress-chart__box_main">
							<div className="student-progress-chart__box_header">
								<h1>{profiles?.first_name} {profiles?.last_name}</h1>
								
							</div>
							<div className="student-progress-chart__header_buttons">
                <button  onClick={()=>history("/StudentProfile",{state:{student:studentDat}})}>Student Profile</button>
                    <button className="active" onClick={()=>history("/StudentProgressChart",{state:{studentDat:studentDat}})}>Track Progress</button>
                    <button  onClick={()=>history("/StudentChart",{state:{student:studentDat}})}>Map Student Views</button>
                    <button onClick={() =>
											history("/StudentComment", {
												state: { student: studentDat },
											})
										}>
                      {/* className="active" */}
                      Evidence Recorded
                    </button>
					<button onClick={() =>
											history("/NotesForStudent", {
												state: { student: studentDat },
											})
										}>
                      {/* className="active" */}
                      Notes From Teacher
                    </button>
                    <button onClick={() =>
											history("/ReviewPastIEP", {
												state: { student: profiles },
											})
										}>IEPs</button>
                    <button onClick={() =>
											history("/IEPDocumentRepositry", {
												state: { student: profiles },
											})
										}>Document Repository</button>
                    
                  </div>
							
							<div className="trackbox__container">
								<div className="header">
									<span>Track Progress</span>
									<div>
										<div className="date_search_div"><p>Start Date :</p><input type={"date"} className="search-date" onChange={e => setStartDate(e.target.value)}/></div>
										<div className="date_search_div"><p>End Date :</p><input type={"date"} className="search-date" onChange={e => setEndDate(e.target.value)}/></div>
										
									<input
          className="search-input"
          value={search}
              onChange={e => setSearch(e.target.value)}
          placeholder="Search"
          
        />
										{/* <div className="learning_outcome">
											<span className="faded">Enter Soft skills</span>
											<DownArrow />
										</div>
										<div className="date">
											<span className="faded">Start Date</span>
											<CalenderIcon />
										</div>
										<div className="date">
											<span className="faded">End Date</span>
											<CalenderIcon />
										</div>
										<div className="search_icon">
											<SearchIcon />
										</div> */}
									</div>
								</div>
								<div className="trackbox_chart" id="trackbox_chart">
								<div className="student-table">
            {results!=undefined&&typeof(results)!="string"&&results.map((value,index)=>(<div><div className="graph-container"><Graph data={value} startDate={startDate} endDate={endDate} color={color[index%5]}/></div>
			<div >
			{value?.category_of_concern?.split(" ").pop().charAt(0)=="("&&<strong style={{backgroundColor:color[index%5],borderRadius:".5rem",padding:".2rem",fontSize:"8px",display:"block",margin:"0 auto",width:"4rem",textAlign:"center"}}>{value?.category_of_concern?.split(" ").pop()}</strong>}
			<p>{value?.category_of_concern}</p>
			</div>
			</div>))}
            
          </div>
									
									
								</div>
								{/* <div className="trackbox_ideas">
									<p>1 = Not yet</p>
									<p>2 = Occassionally</p>
									<p>3 = Sometimes</p>
									<p>4 = Frequently</p>
									<p>5 = Always</p>
								</div> */}
              
               
							</div>
						</div>
					</div><Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={data?.length > 0 ? data?.length : 10}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />{" "}
				</div>
				
			</div>
			<Footer />
		</div>
	);
};

export default StudentProgressChart;
