import React,{ useState, useEffect,useRef,useMemo  } from 'react'
import { Bar, getElementAtEvent } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import { useDispatch } from 'react-redux';
import "./nccd_report.css"
import group from "./../images/Mask Group.png"
import Header from '../Header';
import Footer from '../Footer';
import { useSelector } from 'react-redux';
import { barGraphCounts,barDetailStudentList } from '../../actions/learningcoordinator';
import exportFromJSON from "export-from-json";
import Select from "react-select";

function LearningCoordinator() {
  
  const dispatch = useDispatch();
  const fileName = "download1";
    const [exportType, setExportType] = useState("csv");
    const type_options = [
        { value: "csv", label: "csv" },
        { value: "xls", label: "Excel" },
    ];
  const apiData = useSelector((state) => state.auth); 
  const user= JSON.parse(localStorage.getItem('profile'))
  useEffect(() => {
   
  dispatch(barGraphCounts({learning_coordinator_email:user?.email,school_name:user?.school_name}))
},[])

  const barData=apiData?.bargraphcounts
  const barLabel=barData!=undefined&&JSON.parse(barData).class_year
  const barValue=barData!=undefined&&JSON.parse(barData).bar_data
  const qdtp=barData!=undefined&&barValue.map((value)=>value[0])
  const supplementary=barData!=undefined&&barValue.map((value)=>value[1])
  const extensive=barData!=undefined&&barValue.map((value)=>value[2])
  const substantial=barData!=undefined&&barValue.map((value)=>value[3])
  const other=barData!=undefined&&barValue.map((value)=>value[4])
  
  const chartRef = useRef();
  const onClick = (event) => {
    const year_level=barLabel
    const type=["QDTP","SUPPLEMENTARY","SUBSTANTIAL","EXTENSIVE","OTHER"]
    var bar=getElementAtEvent(chartRef.current, event)[0]
    console.log(year_level[bar.index],type[bar.datasetIndex],bar);
    dispatch(barDetailStudentList({learning_coordinator_email:user?.email,school_name:user?.school_name,year_level:year_level[bar.index],adjustment_type:type[bar.datasetIndex]})) 
  }
  const [data, setData] = useState([])
  useMemo(()=>{
    if(apiData?.bardetailstudentlist!=undefined&&typeof(apiData?.bardetailstudentlist)!="string")
      setData([...apiData?.bardetailstudentlist])
  },[apiData?.bardetailstudentlist]
  )
  const handleSelectChange = (value, action) => {
    setExportType(value["value"]);
};
const [search, setSearch] = useState("")
const [currentPage, setCurrentPage] = useState(1);
let PageSize = 10;
const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return data && data.slice(firstPageIndex, lastPageIndex)
}, [currentPage, data]);
let results
if (search) {
    var searchVar
   
    results = data.filter(item => {
      let found = false
      Object.entries(item).map(([key, value]) => {
        if (String(value).toLowerCase().includes(search.toLowerCase())) {
          found = true
        }
      })
      return found
    })
    results.length = PageSize
  } else {
    results = currentTableData
  }
  const ExportToExcel = () => {
    var data
    
    data=results
    
    exportFromJSON({ data, fileName, exportType })
};
  return (
    <div class="nccd_report">
      <Header/>
    <div class="container">
        <div class="top">
            
            <div class="welcome">
            <p style={{fontSize:"1.2rem",fontWeight:"700",color:"#F05D46"}}>Welcome back {user.first_name }!</p>
            <p style={{fontSize:"small",fontWeight:"500",color:"#092433"}}>Check your dashboard for a quick view of what’s pending and new updates.</p>
            </div>
            <img src={group} class="group"/>
        </div>
        <div class="bottom">
           
            
                
             <div class="graph">
              
            <Bar
            // data={data}
            ref={chartRef}
            onClick={onClick}
          data={{
            
            // Name of the variables on x-axies for each bar
            labels: barData!=undefined&&barLabel,
            datasets: [
             
              {
                // Label for bars
                label:"QDTP",
                // Data or value of your each variable
                data: qdtp,
                // Color of each bar
                backgroundColor: ["#F05D46"],
                // Border color of each bar
                borderColor:["#F05D46"],
                borderWidth: 0.1,
                barThickness: 10,
                borderRadius:5,
                
                
              },{
                // Label for bars
                label:"Supplementary",
                // Data or value of your each variable
                data:supplementary,
                // Color of each bar
                backgroundColor: [ "#69B3DD"],
                // Border color of each bar
                borderColor: [ "#69B3DD"],
                borderWidth: 0.1,
                barThickness: 10,
                borderRadius:5,
                barPercentage:100
                
              },
              {
                // Label for bars
                label:"Substantial",
                // Data or value of your each variable
                data:substantial,
                // Color of each bar
                backgroundColor: [ "#FCB730"],
                // Border color of each bar
                borderColor: [ "#FCB730"],
                borderWidth: 0.1,
                barThickness: 10,
                borderRadius:5,
                
                
              },
              {
                // Label for bars
                label:"Extensive",
                // Data or value of your each variable
                data: extensive,
                // Color of each bar
                backgroundColor: [ "#889159"],
                // Border color of each bar
                borderColor:[ "#889159"],
                borderWidth: 0.1,
                barThickness: 10,
                borderRadius:5,
                
                
              },
              {
                // Label for bars
                label:"Other",
                // Data or value of your each variable
                data: other,
                // Color of each bar
                backgroundColor: [ "#808080"],
                // Border color of each bar
                borderColor:[ "#808080"],
                borderWidth: 0.1,
                barThickness: 10,
                borderRadius:5,
                
                
              },
            ],
          }}
          // Height of graph
          height={400}
          width={1000}
          
          options= {
            {
              
                  
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position:"bottom"
                }},
              scales: {
              x: {
                grid: {
                  display: false
                }
              },
              y: {
                grid: {
                  display: false
                },ticks: {
                  
                  stepSize: 2
                },
              }
            }}
          }
        />
            </div>
            <div class="table">
            <div class="detailsTable">
            <input
							className="search-input"
							value={search}
                  onChange={e => setSearch(e.target.value)}
							placeholder="Search"
							
						/>
                        <div className="download-right">
                            <Select
                                options={type_options}
                                name="date_from"
                                placeholder={
                                    <div className="select-placeholder-text">
                                        Select file type
                                    </div>
                                }
                                value={exportType["label"]}
                                onChange={handleSelectChange}
                                required
                            />
                            <button
                                className="download-button"
                                type="button"
                                onClick={ExportToExcel}
                            >
                                Export
                            </button>
                        </div>
            <table>
                            <thead>
                                <tr class="table100-head">
                                    
                                    <th class="column1">Name</th>
                                    <th class="column2">Email</th>
                                    <th class="column3">Date of Birth</th>
                                    
                                    <th class="column4">Class Year</th>
                                 
                                </tr>
                            </thead>
                            <tbody>
                                {typeof(results)!="string"&&results!=undefined&&results.map((data) => (
                                    <tr>
                                       
                                        
                                        <td class="column1">{data.first_name} {data.last_name}</td>
                                        <td class="column2">{data.email}</td>
                                        
                                       
                                        <td class="column3">{data.date_of_birth}</td>
                                       
                                        <td class="column4">{data.class_year}</td>
                                         </tr>
                                ))}
                            </tbody>
                        </table>
                        </div>
            </div>
           
        </div>
    </div>
    <div style={{clear:'both'}}></div>
    <div style={{height:"2rem"}}></div>
    <Footer/>
    </div>
  )
}

export default LearningCoordinator