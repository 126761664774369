import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./CategoryOfConcern.css"
export const CategoryOfConcern = ({setteacherForm,teacherForm}) => {
	const [form,setForm]=useState({
		
		category_of_concerns:{
			category:"",
			subcategory:"",
			layer1:"",
			layer2:""
		},
		
	})
	const handleCategoryOfConcernChange = (e) => {
		const category_of_concerns=form.category_of_concerns
		category_of_concerns[e.target.name]=e.target.value
		
		setForm({ ...form, category_of_concerns:category_of_concerns });
		setteacherForm({...teacherForm,category_of_concern:category_of_concerns.layer2,percent:form.category_of_concerns.category=="Academic"})
		
	};
	const apiData = useSelector((state) => state.setting.view_category_of_concern);
	console.log(apiData,"cheril")
	const nested_list_data=(typeof(apiData)!="string"&&apiData!=undefined)?apiData:[]
	return (
		<div className="category-container">
						<p className="sub-title">Category of Interest</p>
						{nested_list_data.map((category)=>(
							<>
							<label className="category"><input type={"radio"} name="category" onChange={handleCategoryOfConcernChange} value={category.value} className="checkbox"/>{category.label}</label><br/>
							{category.children.map((subcategory)=>(
								
								form.category_of_concerns.category.includes(category.value)&&
								<><label className="subcategory"><input type={"radio"} name="subcategory" onChange={handleCategoryOfConcernChange} value={subcategory.value} className="checkbox"/>{subcategory.label}</label><br/>
								{subcategory.children.map((layer1)=>(
									form.category_of_concerns.subcategory.includes(subcategory.value)&&
									<>
									<label className="layer1"><input type={"radio"} name="layer1" onChange={handleCategoryOfConcernChange} value={layer1.value} className="checkbox"/>{layer1.label}</label><br/>
									{layer1.children.map((layer2)=>(
										form.category_of_concerns.layer1.includes(layer1.value)&&
										<>
										<label className="layer2" style={{color:layer2.color}}><input type={"radio"} name="layer2" onChange={handleCategoryOfConcernChange} value={layer2.value} className="checkbox"/>{layer2.label}</label><br/>
										</>
									))}
									</>
								))}
								</>
							))}
							</>
						))}
						
					</div>
	);
};
